import { size } from "polished";
import * as React from "react";
import { connect } from "react-redux";
import { Box } from "rebass/styled-components";
import styled from "styled-components/macro";
import { RootState, ThunkDispatch } from "../../core/store";
import { getElementsById } from "../../core/store/elements/reducers";
import { showElementSummary } from "../../core/store/elements/thunks";
import { IElementsById } from "../../core/store/elements/types";
import { IPickLight } from "../../core/store/entries/types";
import { getSettings } from "../../core/store/game/reducers";
import { ISettings } from "../../core/store/game/types";
import { ReactComponent as Sub } from "../../img/icons/sub.svg";
import Badge from "../Badge";
import {
  ElementCardBadge,
  ElementCardMain,
  ElementCardTeam,
  ShirtWrap,
} from "../ElementCard";
import ElementDialogButton from "../ElementDialogButton";
import {
  Controls,
  InfoControl,
  PitchElementInner,
  PitchElementOuter,
  PitchElementWrap,
} from "../Pitch";
import PitchElementData from "../PitchElementData";
import Shirt from "../Shirt";
import TeamCode from "../TeamCode";
import { UnstyledButton } from "../Utils";
import TeamElementIcon from "./TeamElementIcon";

const SubControl = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
`;

const StyledSub = styled(Sub)`
  ${size(13, 48)}
`;

const ElementIconWrap = styled.span`
  position: absolute;
  top: 0;
  right: 10px;
`;

// const DreamTeamWrap = styled.div`
//   position: absolute;
//   top: 44%;
//   right: 0;

//   @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
//     right: 10px;
//   }

//   @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
//     right: 20px;
//   }
// `;

const BenchNumber = styled.h3`
  display: block;
  text-align: right;
  margin: ${({ theme }) => theme.space[2]};
  font-family: ${(props) => props.theme.fonts.action};
  font-size: 2.5rem;
  font-weight: bold;
  font-style: italic;
  text-transform: uppercase;
`;

interface IPropsFromState {
  elementsById: IElementsById;
  settings: ISettings | null;
}

interface IPropsFromDispatch {
  showElementDialog: (elementId: number) => void;
}

interface IOwnProps {
  actionMe: () => void;
  chipName: string | null;
  pick: IPickLight;
  positionOnBench?: number;
  renderDreamTeam: (pick: IPickLight) => React.ReactNode;
  renderElementMenu: () => void;
  renderPickValue: (pick: IPickLight) => React.ReactNode;
}

type Props = IPropsFromState & IPropsFromDispatch & IOwnProps;

const TeamPitchElement: React.FC<Props> = ({
  actionMe,
  chipName,
  pick,
  elementsById,
  positionOnBench,
  renderElementMenu,
  renderPickValue,
  renderDreamTeam,
  settings,
}) => {
  if (!settings) return null;

  const element = elementsById[pick.element];

  return (
    <PitchElementWrap variant={pick.subStatus || ""}>
      <PitchElementOuter>
        <PitchElementInner elementType={element.element_type}>
          <UnstyledButton type="button" onClick={renderElementMenu}>
            <ElementCardMain>
              <ElementCardTeam>
                <ElementCardBadge>
                  <Badge teamId={element.team} isPresentation={true} />
                </ElementCardBadge>
                <Box mr={1}>
                  <TeamCode teamId={element.team} />
                </Box>
              </ElementCardTeam>
              <ShirtWrap>
                <Shirt elementId={element.id} />
              </ShirtWrap>
            </ElementCardMain>
            <PitchElementData element={element} value={renderPickValue(pick)} />
          </UnstyledButton>
          {/* <DreamTeamWrap>{renderDreamTeam(pick)}</DreamTeamWrap> */}
        </PitchElementInner>
        <Controls>
          {actionMe && typeof pick.subStatus !== "undefined" && (
            <SubControl>
              <UnstyledButton type="button" onClick={actionMe}>
                <StyledSub />
              </UnstyledButton>
            </SubControl>
          )}
          <InfoControl>
            <ElementDialogButton elementId={pick.element} />
          </InfoControl>
        </Controls>

        <ElementIconWrap>
          <TeamElementIcon pick={pick} chipName={chipName} />
        </ElementIconWrap>
        {positionOnBench && <BenchNumber>{positionOnBench}</BenchNumber>}
      </PitchElementOuter>
    </PitchElementWrap>
  );
};

const mapStateToProps = (state: RootState): IPropsFromState => ({
  elementsById: getElementsById(state),
  settings: getSettings(state),
});

const mapDispatchToProps = (dispatch: ThunkDispatch): IPropsFromDispatch => ({
  showElementDialog: (elementId) => dispatch(showElementSummary(elementId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TeamPitchElement);
