import { RouteComponentProps } from "@reach/router";
import * as React from "react";
import { connect } from "react-redux";
import styled from "styled-components/macro";
import { RootState, ThunkDispatch } from "../../core/store";
import { getElementTypesBySquadPosition } from "../../core/store/element-types/reducers";
import { IElementTypesBySquadPosition } from "../../core/store/element-types/types";
import {
  showElementSummary,
  updateElementTypeControl,
} from "../../core/store/elements/thunks";
import {
  getProposedElements,
  getSavedPicks,
} from "../../core/store/squad/reducers";
import { removeElement, restoreElement } from "../../core/store/squad/thunks";
import { IProposedElements, ISavedPicks } from "../../core/store/squad/types";
import PitchPlaceholder from "./PitchPlaceholder";
import SquadPitchElement from "./SquadPitchElement";

const StyledSquadPitchUnit = styled.div`
  flex: 0 0 33.3%;
`;

interface IPropsFromState {
  elementTypesByPosition: IElementTypesBySquadPosition | null;
  proposedElements: IProposedElements;
  savedPicks: ISavedPicks;
}

interface IPropsFromDispatch {
  removePitchElement: (position: number) => void;
  restorePitchElement: (position: number) => void;
  showElementDialog: (elementId: number) => void;
  showElementType: (elementTypeId: number) => void;
}

interface IOwnProps {
  pos: number;
  elementValue: React.ReactNode;
  renderElementMenu: (pos: number) => void;
  showSidebar: () => void;
}

type Props = RouteComponentProps &
  IPropsFromState &
  IPropsFromDispatch &
  IOwnProps;

const SquadPitchUnit: React.FC<Props> = ({
  elementTypesByPosition,
  elementValue,
  proposedElements,
  pos,
  renderElementMenu,
  removePitchElement,
  restorePitchElement,
  savedPicks,
  showElementType,
}) => {
  if (!elementTypesByPosition) {
    return null;
  }

  return (
    <StyledSquadPitchUnit>
      {proposedElements[pos] ? (
        <SquadPitchElement
          actionMe={() => {
            removePitchElement(pos);
          }}
          elementId={proposedElements[pos].id}
          elementValue={elementValue}
          renderElementMenu={() => renderElementMenu(pos)}
        />
      ) : savedPicks[pos] ? (
        <SquadPitchElement
          actionMe={() => {
            restorePitchElement(pos);
          }}
          elementId={savedPicks[pos].element}
          elementValue={elementValue}
          renderElementMenu={() => renderElementMenu(pos)}
          isRemoved={true}
        />
      ) : (
        <PitchPlaceholder
          elementTypeId={elementTypesByPosition[pos].thisType.id}
          onClick={() =>
            showElementType(elementTypesByPosition[pos].thisType.id)
          }
        />
      )}
    </StyledSquadPitchUnit>
  );
};

const mapStateToProps = (state: RootState): IPropsFromState => ({
  elementTypesByPosition: getElementTypesBySquadPosition(state),
  proposedElements: getProposedElements(state),
  savedPicks: getSavedPicks(state),
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch,
  ownProps: IOwnProps
): IPropsFromDispatch => ({
  removePitchElement: (position) => {
    dispatch(removeElement(position));
  },
  restorePitchElement: (position) => {
    dispatch(restoreElement(position));
  },
  showElementDialog: (elementId) => dispatch(showElementSummary(elementId)),
  showElementType: (elementTypeId) => {
    dispatch(updateElementTypeControl(elementTypeId));
    ownProps.showSidebar();
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SquadPitchUnit);
