import React from "react";
import styled from "styled-components/macro";
import { PrimaryGradient } from "./Utils";

const StyledDeadlineBar = styled.div`
  ${PrimaryGradient}
  position: relative;
  display: flex;
  justify-content: center;
  padding: 1px;
  border-radius: ${(props) => props.theme.radii[1]};
  align-items: center;
  font-size: 1.8rem;

  ::after {
    ${PrimaryGradient}
    content: "";
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    height: 5px;
    border-bottom-right-radius: ${(props) => props.theme.radii[1]};
    border-bottom-left-radius: ${(props) => props.theme.radii[1]};
  }
`;

const Background = styled.div`
  display: inherit;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: ${(props) => props.theme.space[2]};
  border-radius: 0.4rem;
  background-color: ${(props) => props.theme.colors.white};
`;

const Heading = styled.h3`
  margin-top: 0;
  margin-bottom: 0;
  font-family: ${(props) => props.theme.fonts.action};
  font-size: 2.7rem;
  text-transform: uppercase;

  @media (min-width: ${(props) => props.theme.breakpoints[0]}) {
    font-size: 3rem;
  }
`;

const Deadline = styled.time`
  margin-left: ${(props) => props.theme.space[4]};
  font-weight: bold;
`;

interface IProps {
  /**
   * Local ISO formatted deadline time
   */
  deadlineISO: string;
  /**
   * Human-friendly local deadline time
   */
  deadlineLocal: string;
  /**
   * Deadline heading
   */
  headingText: string;
}

const DeadlineBar: React.FC<IProps> = ({
  deadlineISO,
  deadlineLocal,
  headingText,
}) => (
  <StyledDeadlineBar>
    <Background>
      <Heading>{headingText}:</Heading>
      <span>&nbsp;</span>
      <Deadline dateTime={deadlineISO}>{deadlineLocal}</Deadline>
    </Background>
  </StyledDeadlineBar>
);

export default DeadlineBar;
