import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Box } from "rebass/styled-components";
import { RootState, ThunkDispatch } from "../../../core/store";
import { getEvents } from "../../../core/store/events/reducers";
import { IEvent } from "../../../core/store/events/types";
import { updateClassicLeague } from "../../../core/store/leagues/thunks";
import {
  IPrivateLeagueAdmin,
  IUpdateClassicLeagueData,
  LeagueCodePrivacy,
} from "../../../core/store/leagues/types";
import Button from "../../Button";
import {
  CheckboxField,
  FieldWrap,
  InputField,
  SelectField,
} from "../../FieldRenderers";
import SubHeading from "../../SubHeading";

interface IOwnProps {
  league: IPrivateLeagueAdmin;
}

interface IPropsFromState {
  events: IEvent[];
}

interface IPropsFromDispatch {
  updateLeague: (leagueId: number, data: IUpdateClassicLeagueData) => void;
}

type Props = IOwnProps & IPropsFromState & IPropsFromDispatch & WithTranslation;

interface IState {
  codePrivacy: LeagueCodePrivacy;
  closed: boolean;
  name: string;
  startEvent: number;
}

class ClassicDetails extends React.Component<Props, IState> {
  public requiredFields: Array<keyof IState> = [
    "codePrivacy",
    "name",
    "startEvent",
  ];

  public privacyOptions: { [key: string]: string } = {
    a: "All game players",
    l: "League members",
    p: "League administrator",
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      closed: props.league.closed,
      codePrivacy: props.league.code_privacy,
      name: props.league.name,
      startEvent: props.league.start_event,
    };
  }

  public apiDataFromState = (): IUpdateClassicLeagueData => ({
    admin_entry: this.props.league.admin_entry,
    closed: this.state.closed,
    code_privacy: this.state.codePrivacy,
    name: this.state.name,
    start_event: this.state.startEvent,
  });

  public handleNameChange = (e: React.FormEvent<HTMLInputElement>) =>
    this.setState({ name: e.currentTarget.value });

  public handleCodePrivacyChange = (e: React.FormEvent<HTMLSelectElement>) =>
    this.setState({ codePrivacy: e.currentTarget.value as LeagueCodePrivacy });

  public handleStartEventChange = (e: React.FormEvent<HTMLSelectElement>) =>
    this.setState({ startEvent: parseInt(e.currentTarget.value, 10) });

  public handleClosedChange = (e: React.FormEvent<HTMLInputElement>) =>
    this.setState({ closed: e.currentTarget.checked ? true : false });

  public handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    this.props.updateLeague(this.props.league.id, this.apiDataFromState());
  };

  public render() {
    const { t, events } = this.props;
    const disabled = this.requiredFields.some((e) => !this.state[e]);
    return (
      <>
        <SubHeading>{t("classicDetails.title", "League details")}</SubHeading>
        <form onSubmit={this.handleSubmit}>
          <FieldWrap>
            <InputField
              id="ismLeagueName"
              label={t("classicDetails.labels.name", "League name")}
              value={this.state.name}
              onChange={this.handleNameChange}
              hint={t("classicDetails.hints.name", "Maximum 30 characters")}
              maxLength={30}
              disabled={this.props.league.name_change_blocked}
            />
          </FieldWrap>
          <Box mb={4}>
            <p>
              {this.props.league.name_change_blocked
                ? t(
                    "classicDetails.p1n",
                    "Your requested league name is currently pending moderation and can't be edited at this time."
                  )
                : t(
                    "classicDetails.p1",
                    "Please think carefully before entering your league name. Leagues with names that are deemed inappropriate or offensive may result in your account being deleted. Please refer to the Terms & Conditions of entry for more information."
                  )}
            </p>
          </Box>
          <FieldWrap>
            <SelectField
              id="ismLeagueCodePrivacy"
              value={this.state.codePrivacy}
              onChange={this.handleCodePrivacyChange}
              label={t("classicDetails.labels.privacy", "League code privacy")}
              hint={t(
                "classicDetails.hints.privacy",
                "This sets who the league code is viewable by"
              )}
            >
              {Object.keys(this.privacyOptions).map((p) => (
                <option
                  value={p}
                  aria-selected={p === this.state.codePrivacy}
                  key={p}
                >
                  {this.privacyOptions[p]}
                </option>
              ))}
            </SelectField>
          </FieldWrap>
          <FieldWrap>
            <SelectField
              id="ismLeagueStartEvent"
              value={this.state.startEvent}
              onChange={this.handleStartEventChange}
              label={t("classicDetails.labels.scoringStarts", "Scoring starts")}
              hint={t(
                "classicDetails.hints.scoringStarts",
                "Any change will be reflected after the next points update"
              )}
            >
              {events.map((e) => (
                <option
                  value={`${e.id}`}
                  aria-selected={e.id === this.state.startEvent}
                  key={e.id}
                >
                  {e.name}
                </option>
              ))}
            </SelectField>
          </FieldWrap>
          <FieldWrap>
            <CheckboxField
              checked={this.state.closed}
              id="ismLeagueClosed"
              label={t(
                "classicDetails.labels.closeLeague",
                "Close league to new entries"
              )}
              onChange={this.handleClosedChange}
            />
          </FieldWrap>
          <FieldWrap>
            <Button type="submit" disabled={disabled}>
              {t("classicDetails.cta", "Update league")}
            </Button>
          </FieldWrap>
        </form>
      </>
    );
  }
}

export { ClassicDetails as ClassicDetailsTest };

const mapStateToProps = (state: RootState): IPropsFromState => ({
  events: getEvents(state),
});

const mapDispatchToProps = (dispatch: ThunkDispatch): IPropsFromDispatch => ({
  updateLeague: (leagueId: number, data: IUpdateClassicLeagueData) =>
    dispatch(updateClassicLeague(leagueId, data)),
});

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(ClassicDetails)
);
