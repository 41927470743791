import * as React from "react";
import styled, { css } from "styled-components/macro";

const Item = styled.li`
  flex: 1;
`;

const Link = styled.a<IStyleProps>`
  display: block;
  padding: ${({ theme }) => theme.space[2]};
  background-color: transparent;
  color: inherit;
  text-align: center;
  text-decoration: none;

  ${(props) =>
    props.selected &&
    css`
      background-color: ${({ theme }) => theme.colors.yellow};
    `}
`;

interface IStyleProps {
  selected: boolean;
}

interface IProps {
  key: number;
  index: number;
  label: React.ReactNode;
  link: string;
  onClick: (index: number) => void;
}

type Props = IStyleProps & IProps;

class Tab extends React.Component<Props> {
  public onClick = (e: React.SyntheticEvent) => {
    e.preventDefault();
    this.props.onClick(this.props.index);
  };

  public render() {
    const { label, link, selected } = this.props;
    return (
      <Item>
        <Link href={`#${link}`} onClick={this.onClick} selected={selected}>
          {label}
        </Link>
      </Item>
    );
  }
}

export default Tab;
