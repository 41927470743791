import { size } from "polished";
import styled from "styled-components/macro";

export const ElementCardMain = styled.div`
  border-top-left-radius: ${(props) => props.theme.radii[1]};
  border-top-right-radius: ${(props) => props.theme.radii[1]};
`;

export const ElementCardBadge = styled.div`
  ${size(40)}

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    ${size(50)}
  }
`;

export const ElementCardTeam = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: ${(props) => `0 ${props.theme.space[1]} -${props.theme.space[2]}`};
`;

export const ShirtWrap = styled.div`
  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    width: 140px;
    margin: auto;
  }
`;
