import { hideVisually, size } from "polished";
import * as React from "react";
import styled, { css } from "styled-components/macro";

const Button = styled.button<IStyledProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  ${size(32)};
  border: 1px;
  border-radius: ${(props) => props.theme.radii[2]};
  background-color: ${(props) => props.theme.colors.yellow};
  cursor: pointer;

  ${(props) =>
    props.disabled &&
    css`
      background-color: lightgrey;
      color: grey;
    `}

  ${(props) =>
    props.isSmall &&
    css`
      ${size(24)};
    `}
`;

const HiddenLabel = styled.span`
  ${hideVisually}
`;

interface IStyledProps {
  disabled?: boolean;
  isSmall?: boolean;
}

interface IProps {
  actionMe: () => void;
  children: React.ReactNode;
  label: string;
}

type Props = IProps & IStyledProps;

const PaginatorButton: React.FC<Props> = ({
  actionMe,
  children,
  disabled,
  isSmall,
  label,
}) => (
  <Button
    onClick={actionMe}
    type="button"
    disabled={disabled}
    isSmall={isSmall}
  >
    {children}
    <HiddenLabel>{label}</HiddenLabel>
  </Button>
);

export default PaginatorButton;
