import { formatInTimeZone } from "date-fns-tz";
import * as React from "react";
import { useSelector } from "react-redux";
import styled, { css } from "styled-components/macro";
import { IGroupFixture } from "../core/store/fixtures/types";
import { getTeamsById } from "../core/store/teams/reducers";
import { ITeam } from "../core/store/teams/types";
import { formatRawAsISO } from "../core/utils/datetime";
import Badge from "./Badge";

const StyledFixture = styled.div`
  display: flex;
  align-items: center;
  padding: ${(props) => `${props.theme.space[2]} ${props.theme.space[1]}`};
`;

const StyledTeam = styled.div`
  font-size: 1.3rem;
`;

const TeamStyles = css`
  flex: 0 0 calc(50% - (11rem / 2));
  text-align: start;
`;

const TeamH = styled.div`
  ${TeamStyles}
  display: flex;
  align-items: center;
`;

const TeamA = styled.div`
  ${TeamStyles}
  display: flex;
  align-items: center;
  justify-content: flex-end;
  text-align: end;
`;

const TeamName = styled.div`
  vertical-align: middle;
  font-weight: 500;
  text-decoration: none;
`;

const FixtureBadge = styled.div`
  display: inline-block;
  margin-right: 0.6rem;
  margin-left: 0.6rem;
  width: 40px;
  height: 40px;

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    margin-right: 0.8rem;
    margin-left: 0.8rem;
  }
`;

const FixtureDetails = styled.div`
  position: relative;
  flex: 0 0 11rem;
  padding: ${(props) =>
    `0.2rem  ${props.theme.space[1]} ${props.theme.space[3]}`};
  text-align: center;
`;

const FixtureBox = css`
  margin: 0 1.5rem;
  padding: 0.3rem 0.7rem;
  border-radius: ${(props) => props.theme.radii[2]};
  line-height: 1.1;
  font-weight: 500;
  white-space: nowrap;
`;

const FixtureKOTime = styled.time`
  ${FixtureBox}
  border: 1px solid ${(props) => props.theme.colors.primary};
`;

const FixtureVenue = styled.div`
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  font-size: 1.1rem;
  text-align: center;
`;

const FixtureScore = styled.div`
  ${FixtureBox}
  background-color: ${({ theme }) => theme.colors.primary};
  color: #fff;
`;

interface ITeamProps {
  team: ITeam;
}

const Team: React.FC<ITeamProps> = ({ team }) => (
  <StyledTeam>
    <TeamName>{team.name}</TeamName>
  </StyledTeam>
);
interface IProps {
  fixture: IGroupFixture;
}

const Fixture: React.FC<IProps> = ({ fixture }) => {
  const teamsById = useSelector(getTeamsById);
  const teamH = teamsById[fixture.team_h];
  const teamA = teamsById[fixture.team_a];
  return (
    <StyledFixture>
      <TeamH>
        <FixtureBadge>
          <Badge width="40px" teamId={teamA.id} />
        </FixtureBadge>
        <Team team={teamA} />
      </TeamH>
      <FixtureDetails>
        {fixture.started && fixture.team_h_score !== null ? (
          <FixtureScore>
            {fixture.team_a_score} - {fixture.team_h_score}
          </FixtureScore>
        ) : fixture.kickoff_time ? (
          <FixtureKOTime dateTime={formatRawAsISO(fixture.kickoff_time)}>
            {formatInTimeZone(
              fixture.kickoffDate,
              Intl.DateTimeFormat().resolvedOptions().timeZone,
              "HH:mm"
            )}
          </FixtureKOTime>
        ) : (
          <FixtureKOTime as="span">-</FixtureKOTime>
        )}
        <FixtureVenue>@</FixtureVenue>
      </FixtureDetails>
      <TeamA>
        <Team team={teamH} />
        <FixtureBadge>
          <Badge width="40px" teamId={teamH.id} />
        </FixtureBadge>
      </TeamA>
    </StyledFixture>
  );
};

export default Fixture;
