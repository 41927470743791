import * as React from "react";
import styled, { css } from "styled-components/macro";
import { PrimaryGradient } from "./Utils";

const StyledPanel = styled.div``;

const StyledPanelHeader = styled.div<IPanelHeaderProps>`
  ${PrimaryGradient}
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${({ theme }) => theme.space[2]} ${({ theme }) => theme.space[2]};
  border-top-left-radius: ${(props) => props.theme.radii[1]};
  border-top-right-radius: ${(props) => props.theme.radii[1]};

  ${(props) =>
    props.upperCase &&
    css`
      text-transform: uppercase;
    `}
`;

const PanelHeading = styled.h4`
  flex: 1;
  margin: 0;
  color: ${({ theme }) => theme.colors.white};
  font-family: ${(props) => props.theme.fonts.body};
  font-size: ${({ theme }) => theme.fontSizes[3]};
`;

const StyledPanelFooter = styled.div<IPanelFooterProps>`
  padding: ${({ theme }) =>
    `${theme.space[4]} ${theme.space[2]} ${theme.space[2]}`};
  border-bottom: 1px solid #d8d8d8;

  ${(props) =>
    !props.borderBottom &&
    css`
      border-bottom: none;
    `}
`;

interface IPanelHeaderProps {
  title?: React.ReactNode;
  children?: React.ReactNode;
  upperCase?: boolean;
}

const PanelHeader: React.FC<IPanelHeaderProps> = ({
  children,
  title,
  upperCase,
}) => (
  <StyledPanelHeader upperCase={upperCase}>
    <PanelHeading>{title}</PanelHeading>
    {children}
  </StyledPanelHeader>
);

interface IPanelBodyProps {
  children: React.ReactNode;
}

const PanelBody: React.FC<IPanelBodyProps> = ({ children }) => (
  <div>{children}</div>
);

interface IPanelFooterProps {
  children: React.ReactNode;
  borderBottom?: boolean;
}

const PanelFooter: React.FC<IPanelFooterProps> = ({
  children,
  borderBottom,
}) => (
  <StyledPanelFooter borderBottom={borderBottom}>{children}</StyledPanelFooter>
);

interface IPanelProps {
  children?: React.ReactNode;
}

class Panel extends React.Component<IPanelProps> {
  public static Header = PanelHeader;
  public static Body = PanelBody;
  public static Footer = PanelFooter;

  render() {
    return <StyledPanel>{this.props.children}</StyledPanel>;
  }
}

export default Panel;
