import { navigate, RouteComponentProps } from "@reach/router";
import qs from "qs";
import React from "react";
import {
  useTranslation,
  withTranslation,
  WithTranslation,
} from "react-i18next";
import { connect } from "react-redux";
import { Box } from "rebass/styled-components";
import styled from "styled-components/macro";
import { RootState } from "../core/store";
import { getPlayerData } from "../core/store/player/reducers";
import { IPlayer } from "../core/store/player/types";
import { Main, Wrapper } from "./Layout";
import Login from "./Login";
import Status from "./status/Status";
import SubHeading from "./SubHeading";
import LinkArrowUp from "../img/icons/arrow-up.svg";
import Copy from "./Copy";
import { VisuallyHidden } from "./Utils";
import ButtonLink from "./ButtonLink";
import HomeHero from "./HomeHero";
import PromoVideo from "./PromoVideo";

const DisclaimerText = styled.p`
  font-size: 1rem;
`;

const HomeHeader = styled.div`
  margin: 0 ${(props) => props.theme.space[2]};

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    margin: 0 0 3rem;
  }
`;

const BodySubheading = styled(SubHeading)`
  font-family: ${(props) => props.theme.fonts.action};
  text-transform: uppercase;
  color: inherit;
  font-style: italic;
  font-size: 3rem;
`;

const Highlights = styled.div`
  margin: ${({ theme }) =>
    `${theme.space[4]} ${theme.space[2]} ${theme.space[2]}`};

  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    display: flex;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    margin-left: 0;
    margin-right: 0;
  }
`;

const StyledHomeStep = styled.div`
  border-bottom: 2px solid ${(props) => props.theme.colors.greyLight};
  position: relative;

  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    padding-top: 0;
    border-bottom: 0;
    flex: 1;
    &:not(:first-child)::after {
      content: "";
      position: absolute;
      display: block;
      width: 2px;
      top: 50%;
      height: 100px;
      margin-top: -50px;
      background-color: ${(props) => props.theme.colors.greyLight};
    }
  }
`;

const HomeStepInner = styled.div<IHomeStepStyledProps>`
  padding: ${(props) => props.theme.space[3]};
  background-color: ${(props) => props.theme.colors.white};
  color: ${(props) => props.theme.colors.offBlack};

  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    flex: 1;
  }
`;

const LoginLinkWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: ${({ theme }) => theme.space[3]};
  margin: ${({ theme }) => theme.space[2]} 0;
  width: 100%;
`;

const AnchorButton = styled.div`
  background: center no-repeat url(${LinkArrowUp});
  width: 50px;
  height: 50px;
  border-radius: ${(props) => props.theme.radii[2]};
  background-color: ${(props) => props.theme.colors.yellow};
`;

interface IHomeStepStyledProps {
  isAlt?: boolean;
}

interface IHomeStepProps {
  showButton?: boolean;
  content: string;
  heading: string;
}

type HomeStepProps = IHomeStepProps & IHomeStepStyledProps;

const HomeStep: React.FC<HomeStepProps> = ({
  showButton,
  content,
  heading,
  isAlt,
}) => {
  const { t } = useTranslation();

  return (
    <StyledHomeStep>
      <HomeStepInner isAlt={isAlt}>
        <BodySubheading>{heading}</BodySubheading>
        <p>{content}</p>
        {showButton && (
          <ButtonLink to="/squad-selection" fullwidth="true">
            {t("home.step1Headingn", "Build your roster")}
          </ButtonLink>
        )}
      </HomeStepInner>
    </StyledHomeStep>
  );
};

interface IPropsFromState {
  player: IPlayer | null;
}

type Props = RouteComponentProps & IPropsFromState & WithTranslation;

class Home extends React.Component<Props> {
  public render() {
    const { t, player } = this.props;
    const query = qs.parse(window.location.search, { ignoreQueryPrefix: true });
    if (query.login) {
      switch (query.login) {
        case "success":
          let url = "/";
          if (player && player.entry) {
            // Season start
            url = "/my-team";
          } else if (player && player.id) {
            url = "/squad-selection";
          }
          // Need this to make sure LocationProvider is ready
          setTimeout(() => {
            navigate(url, {
              replace: true,
            });
          }, 0);
          return null;
        default:
          break;
      }
    }

    return (
      <Wrapper>
        <Main isWide={true}>
          <HomeHeader>
            {player && (
              <Box mb={4}>
                <Copy>
                  <p>
                    {t(
                      "login.loggedInAs",
                      "You are logged in as {{firstName}} {{lastName}}",
                      {
                        firstName: player.first_name,
                        lastName: player.last_name,
                      }
                    )}
                  </p>
                </Copy>
              </Box>
            )}
            <VisuallyHidden as="div">
              <h2>{t("home.title", "Home")}</h2>
            </VisuallyHidden>
            {!player && (
              <>
                <Login />
              </>
            )}
          </HomeHeader>
          {!player || !player.entry ? (
            <>
              <HomeHero />
              <Highlights>
                <HomeStep
                  heading={t("home.step1Headingn", "Build your roster")}
                  content={t(
                    "home.step1Contentn",
                    "Pick your 10 player NBA roster using your $100m budget."
                  )}
                  showButton={player && !player.entry ? true : false}
                />

                <HomeStep
                  heading={t("home.step2Heading", "Create Leagues")}
                  content={t(
                    "home.step2Content",
                    "Create leagues and invite your friends to compete throughout the season."
                  )}
                />

                <HomeStep
                  heading={t("home.step3Heading", "Set your line-up")}
                  content={t(
                    "home.step3Contentn",
                    "Choose your line-up for the next gameday and watch your starting 5 score points as they take to the court."
                  )}
                />

                <HomeStep
                  heading={t("home.step4Headingn", "Sign and drop players")}
                  content={t(
                    "home.step4Contentn",
                    "Sign and waive players through the season to improve your team then follow along to see your team score points. "
                  )}
                />
              </Highlights>
            </>
          ) : (
            <Box mb={8}>
              <Status />
            </Box>
          )}
          <PromoVideo />
          {!player && (
            <LoginLinkWrapper>
              <p>{t("home.anchorLinkn", "Back to Login or Register")}</p>
              <a href="#logIn-Register-Link">
                <AnchorButton />
              </a>
            </LoginLinkWrapper>
          )}

          <Box my={4}>
            <Copy>
              <DisclaimerText>
                *{" "}
                {t(
                  "home.disclaimer1n",
                  "No purchase necessary for the entire duration of the competition. You must be 18 years or older. Participation is only available to legal residents of Austria, Belgium, Czechia, Denmark, Finland, Germany, Greece, Hungary, Ireland, Italy, Netherlands, Norway, Portugal, Serbia, Spain, Switzerland, and the United Kingdom."
                )}{" "}
                <a href="/help/rules">
                  {t(
                    "home.disclaimer2n",
                    "Click here for full official rules."
                  )}
                </a>
              </DisclaimerText>
            </Copy>
          </Box>
        </Main>
      </Wrapper>
    );
  }
}

export { Home as HomeTest };

const mapStateToProps = (state: RootState): IPropsFromState => {
  return {
    player: getPlayerData(state),
  };
};

export default withTranslation()(connect(mapStateToProps)(Home));
