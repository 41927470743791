import { IElementBase } from "../core/store/elements/types";

export const isDecimalStat = (stat: any): boolean => {
  const decimalStats: Array<keyof IElementBase> = [
    "event_points",
    "total_points",
    "form",
    "value_form",
    "value_season",
    "points_per_game",
  ];
  return decimalStats.indexOf(stat) > -1;
};

export const integerToDecimal = (int: number, divisor: number) =>
  (int / divisor).toFixed(1);
