import styled from "styled-components/macro";

export const BenchElements = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  @media (min-width: ${(props) => props.theme.breakpoints[5]}) {
    flex-wrap: nowrap;
  }
`;
