import * as React from "react";
import { connect } from "react-redux";
import { ThunkDispatch } from "../../core/store";
import { IPickLight } from "../../core/store/entries/types";
import { actionSubstitution } from "../../core/store/my-team/thunks";
import { PitchRow, PitchUnit } from "../Pitch";
import TeamPitchElement from "./TeamPitchElement";

interface IFormationProps {
  generateProps: (i: number) => {
    actionMe: () => void;
    chipName: string | null;
    pick: IPickLight;
    renderDreamTeam: (pick: IPickLight) => React.ReactNode;
    renderElementMenu: () => void;
    renderPickValue: (pick: IPickLight) => React.ReactNode;
  };
}

const ThreeTwo: React.FC<IFormationProps> = ({ generateProps }) => (
  <>
    <PitchRow>
      <PitchUnit>
        <TeamPitchElement {...generateProps(3)} />
      </PitchUnit>
      <PitchUnit>
        <TeamPitchElement {...generateProps(4)} />
      </PitchUnit>
    </PitchRow>
    <PitchRow>
      <PitchUnit>
        <TeamPitchElement {...generateProps(0)} />
      </PitchUnit>
      <PitchUnit>
        <TeamPitchElement {...generateProps(1)} />
      </PitchUnit>
      <PitchUnit>
        <TeamPitchElement {...generateProps(2)} />
      </PitchUnit>
    </PitchRow>
  </>
);

const TwoThree: React.FC<IFormationProps> = ({ generateProps }) => (
  <>
    <PitchRow>
      <PitchUnit>
        <TeamPitchElement {...generateProps(2)} />
      </PitchUnit>
      <PitchUnit>
        <TeamPitchElement {...generateProps(3)} />
      </PitchUnit>
      <PitchUnit>
        <TeamPitchElement {...generateProps(4)} />
      </PitchUnit>
    </PitchRow>
    <PitchRow>
      <PitchUnit>
        <TeamPitchElement {...generateProps(0)} />
      </PitchUnit>
      <PitchUnit>
        <TeamPitchElement {...generateProps(1)} />
      </PitchUnit>
    </PitchRow>
  </>
);

interface IPropsFromDispatch {
  substitute: (elementId: number) => void;
}

interface IProps {
  chipName: string | null;
  formation: string;
  picks: IPickLight[];
  renderDreamTeam: (pick: IPickLight) => React.ReactNode;
  renderElementMenu: (element: number) => void;
  renderPickValue: (pick: IPickLight) => React.ReactNode;
}

type Props = IPropsFromDispatch & IProps;

interface IFormations {
  [key: string]: React.ReactNode;
}

const PitchFormation: React.FC<Props> = ({
  chipName,
  formation,
  substitute,
  picks,
  renderDreamTeam,
  renderElementMenu,
  renderPickValue,
}) => {
  const sharedTeamPitchElementProps = (i: number) => ({
    actionMe: () => substitute(picks[i].element),
    chipName,
    pick: picks[i],
    renderDreamTeam,
    renderElementMenu: () => renderElementMenu(picks[i].element),
    renderPickValue,
  });
  const formations: IFormations = {
    "3-2": <ThreeTwo generateProps={sharedTeamPitchElementProps} />,
    "2-3": <TwoThree generateProps={sharedTeamPitchElementProps} />,
  };
  return <>{formations[formation]}</>;
};

const mapDispatchToProps = (dispatch: ThunkDispatch): IPropsFromDispatch => ({
  substitute: (elementId) => dispatch(actionSubstitution(elementId)),
});

export default connect(null, mapDispatchToProps)(PitchFormation);
