import * as React from "react";
import styled, { css } from "styled-components/macro";
import { ScoreboardBox } from "./Scoreboard";

const Heading = styled.h3`
  font-family: ${(props) => props.theme.fonts.body};
  font-size: ${(props) => props.theme.fontSizes[2]};
  font-weight: normal;
  line-height: 1;
`;

const Value = styled.div<IValue>`
  display: inline-block;
  border-radius: 11px;
  padding: ${(props) => props.theme.space[1]} ${(props) => props.theme.space[2]}
    0.4rem;
  font-family: ${(props) => props.theme.fonts.action};
  font-size: 3rem;
  line-height: 1;

  ${(props) =>
    props.isError &&
    css`
      background-color: ${props.theme.colors.error};
      color: ${props.theme.colors.white};
    `}

  ${(props) =>
    props.isSuccess &&
    css`
      background-color: ${props.theme.colors.success};
    `}
`;

interface IScoreboardPanel {
  heading: string;
  value: string;
}

interface IValue {
  isError?: boolean;
  isSuccess?: boolean;
}

type Props = IScoreboardPanel & IValue;

export const ScoreboardPanel: React.FC<Props> = ({
  heading,
  isError,
  isSuccess,
  value,
}) => (
  <ScoreboardBox>
    <Heading>{heading}</Heading>
    <Value isError={isError} isSuccess={isSuccess}>
      {value}
    </Value>
  </ScoreboardBox>
);

export default ScoreboardPanel;
