import React from "react";
import styled, { css } from "styled-components/macro";

const StyledButton = styled.button<IButtonStyledProps>`
  display: block;
  padding: 1.5rem ${(props) => props.theme.space[3]};
  border: 0;
  background-color: ${(props) => props.theme.colors.yellow};
  font-family: ${(props) => props.theme.fonts.body};
  font-size: ${(props) => props.theme.fontSizes[1]};
  font-weight: 500;
  border-radius: ${(props) => props.theme.radii[1]};
  color: black;
  text-align: center;
  line-height: 1;
  cursor: pointer;

  ${(props) =>
    props.variant === "secondary" &&
    css`
      background-color: ${(props) => props.theme.colors.secondary};
      color: ${(props) => props.theme.colors.white};
    `}

  ${(props) =>
    props.variant === "tertiary" &&
    css`
      background-color: ${(props) => props.theme.colors.tertiary};
      color: ${(props) => props.theme.colors.white};
    `}

  ${(props) =>
    props.disabled &&
    css`
      border: 1px solid ${(props) => props.theme.colors.offBlack};
      background-color: ${(props) => `${props.theme.colors.greyLight}99`};
      color: ${(props) => props.theme.colors.offBlack};
      cursor: default;
      &:hover {
        cursor: not-allowed;
      }
    `}

  ${(props) =>
    props.fullwidth &&
    css`
      width: 100%;
    `};
`;

export interface IButtonStyledProps {
  /**
   * Is the button disabled?
   */
  disabled?: boolean;
  /**
   * Should the button be the full width of the container?
   */
  fullwidth?: "true" | "";
  /**
   * Set visual prominence of the button
   */
  variant?: "primary" | "secondary" | "tertiary";
}

interface IProps {
  /**
   * Optional click handler
   */
  onClick?: () => void;
  /** Specifiy the button type */
  type?: "button" | "submit" | "reset";
}

type Props = IButtonStyledProps & IProps;

const Button: React.FC<Props> = ({
  children,
  disabled,
  fullwidth = "",
  type = "button",
  variant = "primary",
  ...rest
}) => (
  <StyledButton
    type={type}
    fullwidth={fullwidth}
    variant={variant}
    disabled={disabled}
    {...rest}
  >
    {children}
  </StyledButton>
);

export default Button;
