import { size } from "polished";
import React from "react";
import { connect } from "react-redux";
import styled from "styled-components/macro";
import { RootState } from "../../core/store";
import { getElementsById } from "../../core/store/elements/reducers";
import { IElementsById } from "../../core/store/elements/types";
import { IPickLight } from "../../core/store/entries/types";
import { getSettings } from "../../core/store/game/reducers";
import { ISettings } from "../../core/store/game/types";
import { ReactComponent as Captain } from "../../img/icons/captain.svg";

const StyledCaptain = styled(Captain)<IStyledCaptainProps>`
  ${size(14)};
  color: white;

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    ${size(16)}
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    ${size(20)}
  }
`;

interface IPropsFromState {
  elementsById: IElementsById;
  settings: ISettings | null;
}

interface IOwnProps {
  chipName: string | null;
  pick: IPickLight;
}

interface IStyledCaptainProps {
  variant?: string;
}

type Props = IPropsFromState & IOwnProps & IStyledCaptainProps;

const TeamElementIcon: React.FC<Props> = ({ chipName, pick, settings }) => {
  if (chipName && chipName === "phcapt") {
    if (pick.is_captain) return <StyledCaptain />;
  }
  return null;
};

const mapStateToProps = (state: RootState): IPropsFromState => ({
  elementsById: getElementsById(state),
  settings: getSettings(state),
});

export default connect(mapStateToProps)(TeamElementIcon);
