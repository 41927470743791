import React from "react";
import { useSelector } from "react-redux";
import styled, { css } from "styled-components/macro";
import { getTeamsById } from "../core/store/teams/reducers";
import i18n from "../i18n";

const StyledTeamCode = styled.p<IStyledProps>`
  display: inline-block;
  margin: 0;
  color: ${(props) => props.theme.colors.teams[props.code].dark};
  font-size: 1.5rem;
  font-weight: ${(props) => (props.isSmall ? "500" : "normal")};
  text-decoration: none;

  ${(props) =>
    !props.isSmall &&
    css`
      font-family: ${(props) => props.theme.fonts.action};
      font-size: 3rem;

      @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
        font-size: 3.5rem;
      }
    `}

  ${i18n.language === "tr" &&
  css`
    text-transform: lowercase;
    &::first-letter {
      text-transform: uppercase;
    }
  `}
`;

interface IStyledProps {
  isSmall?: boolean;
  code: number;
}

interface IProps {
  isSmall?: boolean;
  teamId: number;
}

const TeamCode: React.FC<IProps> = ({ isSmall, teamId }) => {
  const teamsById = useSelector(getTeamsById);
  const team = teamsById[teamId];

  return (
    <StyledTeamCode code={team.code} isSmall={isSmall} title={team.name}>
      <strong>{team.short_name}</strong>
    </StyledTeamCode>
  );
};

export default TeamCode;
