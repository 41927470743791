import React from "react";
import styled from "styled-components/macro";
import { ReactComponent as PlusIcon } from "../img/icons/plus.svg";

const StyledPlusIcon = styled(PlusIcon)`
  margin: 2.6rem 0;
  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    margin: ${({ theme }) => theme.space[8]} 0;
  }
`;

const PlaceholderShirt: React.FC = () => (
  <StyledPlusIcon>Placeholder</StyledPlusIcon>
);

export default PlaceholderShirt;
