import orderBy from "lodash/orderBy";
import { useTranslation } from "react-i18next";
import { Box } from "rebass";
import styled, { css } from "styled-components";
import { IElement } from "../../core/store/elements/types";
import i18n from "../../i18n";
import FdjPng1x from "../../img/fdj-logo-only@1x.png";
import FdjPng2x from "../../img/fdj-logo-only@2x.png";
import { integerToDecimal } from "../../utils/decimal";
import Badge from "../Badge";
import {
  ElementCardBadge,
  ElementCardMain,
  ElementCardTeam,
  ShirtWrap,
} from "../ElementCard";
import { PitchElementInner } from "../Pitch";
import { ElementName, StyledPitchElementData } from "../PitchElementData";
import Shirt from "../Shirt";
import ElementGroup from "../squad/ElementGroup";
import SubHeading from "../SubHeading";
import TeamCode from "../TeamCode";

const StyledElementName = styled(ElementName)`
  text-align: center;
`;

const Score = styled.div<{ elementTypeId: number }>`
  background-image: linear-gradient(
    to right,
    ${(props) => props.theme.colors.elementTypes[props.elementTypeId].colors[0]}
      75%,
    ${(props) => props.theme.colors.elementTypes[props.elementTypeId].colors[1]}
  );
  border-bottom-right-radius: ${(props) => props.theme.radii[2]};
  border-bottom-left-radius: ${(props) => props.theme.radii[2]};
  padding: ${(props) => props.theme.space[1]};
  color: ${(props) => props.theme.colors.white};
  font-family: ${(props) => props.theme.fonts.action};
  font-size: 2.5rem;
  text-align: center;
`;

const Rank = styled.div`
  align-self: center;
  padding: ${(props) => props.theme.space[1]};
  font-family: ${(props) => props.theme.fonts.action};
  font-size: 2.2rem;
  font-style: italic;
`;

const CardWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  flex: 0 1 140px;
  max-width: 140px;
  gap: ${(props) => props.theme.space[1]};
  margin: ${(props) => props.theme.space[1]};

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    margin: ${(props) => props.theme.space[0]};
  }
`;

const SponsorWrap = styled.div`
  margin: ${(props) => `${props.theme.space[4]} ${props.theme.space[2]}`};
  @media (min-width: ${(props) => props.theme.breakpoints[3]}) {
    margin-left: 0;
  }

  ${i18n.language === "fr" &&
  css`
    display: flex;
    align-items: center;
    gap: ${(props) => props.theme.space[2]};
  `};
`;

const StyledSubHeading = styled(SubHeading)`
  margin: 0;
  text-transform: uppercase;
`;

const TopScorersHeading = () => {
  const { t } = useTranslation();
  return (
    <SponsorWrap>
      <StyledSubHeading>
        {t("status.topScorers", "Top Gameday Performers")}
      </StyledSubHeading>
      {i18n.language === "fr" && (
        <img
          srcSet={`${FdjPng1x} 1x, ${FdjPng2x} 2x`}
          src={`${FdjPng1x}`}
          alt="Présenté Par Parions Sport en ligne"
        />
      )}
    </SponsorWrap>
  );
};

const TopGamedayScorers = ({ elements }: { elements: IElement[] }) => {
  return (
    <>
      <Box mb={4}>
        <ElementGroup
          elementTypeId={elements[0].element_type}
          $showBackground={false}
          $justifyContentCenter={false}
        >
          {orderBy(elements, ["event_points", "now_cost"], ["desc", "desc"])
            .slice(0, 5)
            .map((element: IElement, index: number) => (
              <CardWrap key={element.id}>
                <Rank>{index + 1}</Rank>
                <PitchElementInner>
                  <ElementCardMain>
                    <ElementCardTeam>
                      <ElementCardBadge>
                        <Badge teamId={element.team} isPresentation={true} />
                      </ElementCardBadge>
                      <Box mr={1}>
                        <TeamCode teamId={element.team} />
                      </Box>
                    </ElementCardTeam>
                    <ShirtWrap>
                      <Shirt elementId={element.id} />
                    </ShirtWrap>
                    <StyledPitchElementData
                      elementTypeId={element.element_type}
                    >
                      <StyledElementName>
                        {Array.from(element.first_name)[0]}.
                        <strong>{element.second_name}</strong>
                      </StyledElementName>
                    </StyledPitchElementData>
                    <Score elementTypeId={element.element_type}>
                      {integerToDecimal(element.event_points, 10)}
                    </Score>
                  </ElementCardMain>
                </PitchElementInner>
              </CardWrap>
            ))}
        </ElementGroup>
      </Box>
    </>
  );
};

const TopScorers = ({ elements }: { elements: IElement[] }) => {
  const groupedElements: { front_court: IElement[]; back_court: IElement[] } = {
    front_court: [],
    back_court: [],
  };

  Object.values(elements).forEach((element: IElement) => {
    if (element.element_type === 2) {
      groupedElements.front_court.push(element);
    } else if (element.element_type === 1) {
      groupedElements.back_court.push(element);
    }
  });

  return (
    <>
      <TopScorersHeading />
      <TopGamedayScorers elements={groupedElements.front_court} />
      <TopGamedayScorers elements={groupedElements.back_court} />
    </>
  );
};

export default TopScorers;
