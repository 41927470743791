import { size } from "polished";
import * as React from "react";
import { useSelector } from "react-redux";
import { Box } from "rebass/styled-components";
import styled from "styled-components/macro";
import { RootState } from "../../core/store";
import { getElement } from "../../core/store/elements/reducers";
import { ReactComponent as Remove } from "../../img/icons/remove.svg";
import Badge from "../Badge";
import {
  ElementCardBadge,
  ElementCardMain,
  ElementCardTeam,
  ShirtWrap,
} from "../ElementCard";
import ElementDialogButton from "../ElementDialogButton";
import {
  Controls,
  InfoControl,
  PitchElementInner,
  PitchElementOuter,
  PitchElementWrap,
  PrimaryControl,
} from "../Pitch";
import PitchElementData from "../PitchElementData";
import PlaceholderShirt from "../PlaceholderShirt";
import Shirt from "../Shirt";
import TeamCode from "../TeamCode";
import { UnstyledButton } from "../Utils";

const StyledRemove = styled(Remove)`
  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    ${size(20)}
  }
`;

interface IOwnProps {
  actionMe: () => void;
  elementId: number;
  elementValue: React.ReactNode;
  renderElementMenu: () => void;
}

interface IStyleProps {
  isRemoved?: boolean;
}
type Props = IOwnProps & IStyleProps;

const SquadPitchElement: React.FC<Props> = ({
  actionMe,
  elementId,
  elementValue,
  isRemoved,
  renderElementMenu,
}) => {
  const element = useSelector((state: RootState) =>
    getElement(state, elementId)
  );

  if (!element) {
    return null;
  }

  return (
    <PitchElementWrap>
      <PitchElementOuter>
        <PitchElementInner
          isRemoved={isRemoved}
          elementType={element.element_type}
        >
          <UnstyledButton type="button" onClick={renderElementMenu}>
            {isRemoved ? (
              <PlaceholderShirt />
            ) : (
              <ElementCardMain>
                <ElementCardTeam>
                  <ElementCardBadge>
                    <Badge teamId={element.team} isPresentation={true} />
                  </ElementCardBadge>
                  <Box mr={1}>
                    <TeamCode teamId={element.team} />
                  </Box>
                </ElementCardTeam>
                <ShirtWrap>
                  <Shirt elementId={element.id} />
                </ShirtWrap>
              </ElementCardMain>
            )}
            <PitchElementData element={element} value={elementValue} />
          </UnstyledButton>
        </PitchElementInner>
        <Controls>
          <PrimaryControl>
            <UnstyledButton type="button" onClick={actionMe}>
              <StyledRemove />
            </UnstyledButton>
          </PrimaryControl>
          <InfoControl>
            <ElementDialogButton elementId={element.id} />
          </InfoControl>
        </Controls>
      </PitchElementOuter>
    </PitchElementWrap>
  );
};

export default SquadPitchElement;
