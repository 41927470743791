import React from "react";
import styled, { css } from "styled-components/macro";
import { IElement } from "../core/store/elements/types";
import i18n from "../i18n";
import PitchElementFixtures from "./PitchElementFixtures";

export const StyledPitchElementData = styled.div<IStyledPitchElementData>`
  padding-top: ${(props) => props.theme.space[1]};
  border-bottom-left-radius: ${(props) => props.theme.radii[1]};
  border-bottom-right-radius: ${(props) => props.theme.radii[1]};

  ${(props) =>
    props.elementTypeId &&
    css`
      background-image: linear-gradient(
        to right,
        ${props.theme.colors.elementTypes[props.elementTypeId].colors[0]} 75%,
        ${props.theme.colors.elementTypes[props.elementTypeId].colors[1]}
      );
    `};
`;

export const ElementName = styled.div`
  padding: 0.3rem;
  background-color: blue;
  color: black;
  background-color: white;
  font-family: ${(props) => props.theme.fonts.action};
  font-size: 2.2rem;
  text-transform: uppercase;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  ${i18n.language === "tr" &&
  css`
    text-transform: capitalize;
  `}

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    padding: 0.4rem;
    font-size: 3rem;
  }
`;

export const ElementValue = styled.div`
  padding: 0.1rem;
  color: white;
  font-family: ${(props) => props.theme.fonts.action};
  font-size: 2rem;
  font-weight: bold;

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    padding: 0.6rem;
    font-size: 3.5rem;
  }
`;

interface IStyledPitchElementData {
  elementTypeId: number;
}

interface IProps {
  element: IElement;
  value: React.ReactNode;
}

const PitchElementData: React.FC<IProps> = ({ element, value }) => (
  <StyledPitchElementData elementTypeId={element.element_type}>
    <ElementName>
      {Array.from(element.first_name)[0]}.<strong>{element.second_name}</strong>
    </ElementName>
    <ElementValue>{value}</ElementValue>
    <PitchElementFixtures element={element} />
  </StyledPitchElementData>
);

export default PitchElementData;
