import styled from "styled-components/macro";

const SaveBar = styled.div`
  margin-bottom: ${(props) => props.theme.space[4]};
  padding: 0 20% ${(props) => props.theme.space[4]};
  border-bottom: 3px solid #bfbfbf;

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    margin-right: 10%;
    margin-left: 10%;
  }
`;

export default SaveBar;
