import styled from "styled-components";
import AdImageLarge from "../../img/page-ad-image-768.png";
import AdImageSmall from "../../img/page-ad-image-384.png";

const AdWrap = styled.div`
  width: 95%;
  margin-left: auto;
  margin-top: ${({ theme }) => theme.space[1]};
  margin-right: auto;
  margin-bottom: ${({ theme }) => theme.space[6]};

  @media (min-width: ${({ theme }) => theme.breakpoints[0]}) {
    width: 85%;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    width: 70%;
  }
`;

const PageAd = () => {
  return (
    <AdWrap>
      <a
        target="_blank"
        rel="noreferrer"
        href="https://ad.doubleclick.net/ddm/trackclk/N30402.2403113NBA/B25332343.407727289;dc_trk_aid=599854809;dc_trk_cid=225077246;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;ltd=;dc_tdv=1"
      >
        <picture>
          <source media="(max-width: 400px)" srcSet={`${AdImageSmall}`} />
          <source media="(min-width: 401px)" srcSet={`${AdImageLarge}`} />
          <img
            src={AdImageSmall}
            srcSet={`${AdImageSmall} 400w, ${AdImageLarge} 1200w`}
            sizes="(max-width: 400px) 100vw, 1200px"
            alt="Présenté Par Parions Sport en ligne"
            width="100%"
          />
        </picture>
      </a>
    </AdWrap>
  );
};

export default PageAd;
