import { size } from "polished";
import styled, { css } from "styled-components/macro";
import i18n from "../../i18n";
import { ReactComponent as ArrowLeftDoubleIcon } from "../../img/icons/arrow-double-left.svg";
import { ReactComponent as ArrowRightDoubleIcon } from "../../img/icons/arrow-double-right.svg";
import { ReactComponent as ArrowLeftIcon } from "../../img/icons/arrow-left.svg";
import { ReactComponent as ArrowRightIcon } from "../../img/icons/arrow-right.svg";
import { isRTL } from "../../utils/locale";

interface IArrow {
  color?: "blueDark" | "";
}

// RTL: start = right, end = left
// LTR: start = left, end = right

export const ArrowStart = isRTL(i18n.language)
  ? styled(ArrowRightIcon)<IArrow>`
      fill: ${(props) =>
        props.color ? props.theme.colors[props.color] : "currentColor"};
    `
  : styled(ArrowLeftIcon)<IArrow>`
      fill: ${(props) =>
        props.color ? props.theme.colors[props.color] : "currentColor"};
    `;

export const ArrowEnd = isRTL(i18n.language)
  ? styled(ArrowLeftIcon)<IArrow>`
      fill: ${(props) =>
        props.color ? props.theme.colors[props.color] : "currentColor"};
    `
  : styled(ArrowRightIcon)<IArrow>`
      fill: ${(props) =>
        props.color ? props.theme.colors[props.color] : "currentColor"};
    `;

export const ArrowStartDouble = isRTL(i18n.language)
  ? styled(ArrowRightDoubleIcon)<IArrow>`
      fill: ${(props) =>
        props.color ? props.theme.colors[props.color] : "currentColor"};
    `
  : styled(ArrowLeftDoubleIcon)<IArrow>`
      fill: ${(props) =>
        props.color ? props.theme.colors[props.color] : "currentColor"};
    `;

export const ArrowEndDouble = isRTL(i18n.language)
  ? styled(ArrowLeftDoubleIcon)<IArrow>`
      fill: ${(props) =>
        props.color ? props.theme.colors[props.color] : "currentColor"};
    `
  : styled(ArrowRightDoubleIcon)<IArrow>`
      fill: ${(props) =>
        props.color ? props.theme.colors[props.color] : "currentColor"};
    `;

export const ControlArrowStart = styled(ArrowStart)`
  ${size(8, 5)};
  ${isRTL(i18n.language)
    ? css`
        margin-left: 0.3rem;
      `
    : css`
        margin-right: 0.3rem;
      `}
`;

export const ControlArrowEnd = styled(ArrowEnd)`
  ${size(8, 5)};
  ${isRTL(i18n.language)
    ? css`
        margin-right: 0.3rem;
      `
    : css`
        margin-left: 0.3rem;
      `}
`;
