import React from "react";
import { Box } from "rebass/styled-components";
import styled, { css } from "styled-components/macro";
import { ElementCardTeam } from "../ElementCard";
import { StyledShirt } from "../Shirt";
import { VisuallyHidden } from "../Utils";
import BackCourtBg from "../../img/back-court-bg.png";
import FrontCourtBg from "../../img/front-court-bg.png";

const StyledTeamElementGroup = styled.div<IStyledProps>`
  padding: 4px;
  border-radius: ${(props) => props.theme.radii[1]};
  background-image: linear-gradient(
    to bottom,
    ${(props) => props.theme.colors.red},
    ${(props) => props.theme.colors.blue}
  );
  color: #fff;

  ${(props) =>
    props.isBench &&
    css`
      background-image: linear-gradient(
        to bottom,
        #c7c7c7,
        ${(props) => props.theme.colors.greyLight}
      );
      color: #000;

      ${StyledShirt} {
        display: none;
      }

      ${ElementCardTeam} {
        margin-bottom: 0;
      }
    `}
`;

const TeamElementGroupTitle = styled.h3`
  margin: 0;
  font-family: ${(props) => props.theme.fonts.body};
  font-size: ${(props) => props.theme.fontSizes[3]};
  text-transform: uppercase;
`;

const TeamElementGroupBody = styled.div<IStyledProps>`
  border-radius: 2px;
  background-color: #fff;
  padding: ${(props) => `${props.theme.space[3]} 0`};

  ${(props) =>
    !props.isBench &&
    css`
      @media (min-width: ${(props) => props.theme.breakpoints[2]}) {
        background-image: url(${FrontCourtBg}), url(${BackCourtBg});
        background-repeat: no-repeat;
        background-position: top left, bottom right;
      }
    `}
`;

export const PitchRow = styled.div`
  display: flex;
  justify-content: center;
`;

interface IStyledProps {
  isBench?: boolean;
}

interface IProps {
  isHiddenTitle?: boolean;
  title: React.ReactNode;
}

type Props = IProps & IStyledProps;

const TeamElementGroup: React.FC<Props> = ({
  children,
  isBench = false,
  isHiddenTitle = false,
  title,
}) => (
  <StyledTeamElementGroup isBench={isBench}>
    <TeamElementGroupTitle>
      {isHiddenTitle ? (
        <VisuallyHidden>{title}</VisuallyHidden>
      ) : (
        <Box pb="0.4rem" pl="0.4rem">
          {title}
        </Box>
      )}
    </TeamElementGroupTitle>
    <TeamElementGroupBody isBench={isBench}>{children}</TeamElementGroupBody>
  </StyledTeamElementGroup>
);

export default TeamElementGroup;
