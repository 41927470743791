import { IElement } from "../core/store/elements/types";

// news_added is string || null, so  to
// convert it to a number in order to compare it.
export const compareDates = (a: IElement, b: IElement) => {
  const dateA = a.news_added ? new Date(a.news_added).getTime() : 0;
  const dateB = b.news_added ? new Date(b.news_added).getTime() : 0;
  return dateA - dateB;
};

export const comparePrice = (a: IElement, b: IElement) => {
  return b.now_cost - a.now_cost;
};
