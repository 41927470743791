import * as React from "react";
import { connect } from "react-redux";
import styled from "styled-components/macro";
import { ThunkDispatch } from "../../core/store";
import { IPickLight } from "../../core/store/entries/types";
import { actionSubstitution } from "../../core/store/my-team/thunks";
import TeamPitchElement from "./TeamPitchElement";

const StyledBenchUnit = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding-top: ${({ theme }) => theme.space[1]};
  flex: 0 0 calc(100% / 3);

  @media (min-width: ${(props) => props.theme.breakpoints[5]}) {
    flex: 1 0 auto;
  }
`;

const BenchUnitHeading = styled.h3`
  order: 2;
  margin: 0.2rem 0;
  color: #61605c;
  font-size: ${({ theme }) => theme.fontSizes[0]};
  text-align: center;

  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    font-size: ${({ theme }) => theme.fontSizes[3]};
  }
`;

interface IPropsFromDispatch {
  substitute: (elementId: number) => void;
}

interface IProps {
  chipName: string | null;
  heading: React.ReactNode;
  pick: IPickLight;
  positionOnBench?: number;
  renderDreamTeam: (pick: IPickLight) => React.ReactNode;
  renderElementMenu: (pick: IPickLight) => void;
  renderPickValue: (element: IPickLight) => React.ReactNode;
  substitute: (elementId: number) => void;
}

type Props = IPropsFromDispatch & IProps;

const BenchUnit: React.FC<Props> = ({
  chipName,
  heading,
  pick,
  positionOnBench,
  renderDreamTeam,
  renderElementMenu,
  renderPickValue,
  substitute,
}) => (
  <StyledBenchUnit>
    <BenchUnitHeading>{heading}</BenchUnitHeading>
    <TeamPitchElement
      actionMe={() => substitute(pick.element)}
      chipName={chipName}
      pick={pick}
      positionOnBench={positionOnBench}
      renderDreamTeam={renderDreamTeam}
      renderElementMenu={() => renderElementMenu(pick)}
      renderPickValue={renderPickValue}
    />
  </StyledBenchUnit>
);

const mapDispatchToProps = (dispatch: ThunkDispatch): IPropsFromDispatch => ({
  substitute: (elementId) => dispatch(actionSubstitution(elementId)),
});

export default connect(null, mapDispatchToProps)(BenchUnit);
