import { Link } from "@reach/router";
import React from "react";
import styled, { css } from "styled-components/macro";
import { INavLink } from "../../core/hooks/types";
import useDocumentEventHandler from "../../core/hooks/useDocumentEventHandler";
import "./Dropdown.css";
import { isRTL } from "../../utils/locale";
import i18n from "../../i18n";

const MoreButton = styled.button`
  display: inline-block;
  margin: 0;
  padding: 1.5rem 2rem;
  border: 0;
  background-color: white;
  color: black;
  border-radius: ${(props) =>
    `${props.theme.radii[1]} ${props.theme.radii[1]} 0 0`};
  font-family: inherit;
  font-size: 1.3rem;
  font-weight: bold;
  text-decoration: none;
  cursor: pointer;
`;

const NavLink = css`
  display: block;
  padding: 0 1.8rem;
  background-color: white;
  color: currentColor;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 4.7rem;
  text-align: center;
  text-decoration: none;
  transition: all 0.2s;
  cursor: pointer;

  :hover {
    background-color: ${({ theme }) => theme.colors.yellow};
  }
`;

const NavReachLink = styled(Link)`
  ${NavLink}
`;

const NavHyperLink = styled.a`
  ${NavLink}
`;

type Props = {
  text: string;
  links: INavLink[];
};

const Dropdown: React.FunctionComponent<Props> = ({ text, links }) => {
  const [open, setOpen] = React.useState(false);
  useDocumentEventHandler({ event: "click", handler: () => setOpen(false) });
  const toggleOpen = (e: React.SyntheticEvent) => {
    e.nativeEvent.stopImmediatePropagation();
    setOpen((open) => !open);
  };

  return (
    <span className="dropdown" aria-haspopup="true">
      <MoreButton
        aria-controls="menu"
        className="button"
        type="button"
        onClick={toggleOpen}
      >
        {text}
      </MoreButton>
      <ul
        aria-hidden={!open}
        dir={isRTL(i18n.language) ? "rtl" : "ltr"}
        className={`list ${open && "show"}`}
      >
        {links.map((link) => (
          <li key={link.text}>
            {link.useRouter ? (
              <NavReachLink to={link.href}>{link.text}</NavReachLink>
            ) : (
              <NavHyperLink
                href={link.href}
                rel={link.rel}
                target={link.target}
              >
                {link.text}
              </NavHyperLink>
            )}
          </li>
        ))}
      </ul>
    </span>
  );
};

export default Dropdown;
