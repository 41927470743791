import React from "react";
import { useTranslation } from "react-i18next";
import { IElement, IElementFixture } from "../core/store/elements/types";
import { IFixture } from "../core/store/fixtures/types";
import { ITeamsById } from "../core/store/teams/types";
import Badge from "./Badge";

interface IProps {
  showBadge?: boolean;
  fixture: IFixture | IElementFixture;
  element: IElement;
  teamsById: ITeamsById;
}

const FixtureForElement: React.FC<IProps> = ({
  showBadge,
  fixture,
  element,
  teamsById,
}) => {
  const isHome = element.team === fixture.team_h;
  const { t } = useTranslation();
  return isHome ? (
    <>
      {showBadge && (
        <Badge teamId={teamsById[fixture.team_a].id} width="23px"></Badge>
      )}

      <span>
        {t("fixtureForElement.oppHome", "vs.{{ shortName }}", {
          shortName: teamsById[fixture.team_a].short_name,
        })}
      </span>
    </>
  ) : (
    <>
      {showBadge && (
        <Badge teamId={teamsById[fixture.team_h].id} width="23px"></Badge>
      )}
      <span>
        {t("fixtureForElement.oppAway", "@{{ shortName }}", {
          shortName: teamsById[fixture.team_h].short_name,
        })}
      </span>
    </>
  );
};

export default FixtureForElement;

interface IMultiProps {
  myTeam?: boolean;
  delimiter?: React.ReactNode;
  fixtures: IFixture[] | any;
  element: IElement;
  teamsById: ITeamsById;
}

export const FixturesForElement: React.FC<IMultiProps> = ({
  myTeam,
  delimiter = ", ",
  fixtures,
  element,
  teamsById,
}) => {
  const fragments = fixtures.map(
    (f: any) =>
      !myTeam && (
        <FixtureForElement
          element={element}
          fixture={f}
          teamsById={teamsById}
          key={f.id}
        />
      )
  );
  return fragments && fragments.length
    ? fragments.reduce((result: any, item: any) => (
        <>
          {result}
          {delimiter}
          {item}
        </>
      ))
    : null;
};
