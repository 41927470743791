import styled, { css } from "styled-components/macro";
import i18n from "../i18n";
import { isRTL } from "../utils/locale";

export const Pager = styled.div`
  display: flex;
`;

const PagerButtonNextStyles = isRTL(i18n.language)
  ? css`
      margin-right: auto;
    `
  : css`
      margin-left: auto;
    `;

export const EventPager = styled.div`
  display: flex;

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    margin-top: -4.5rem;
  }
`;

export const PagerItem = styled.div`
  flex: 1;
  padding: 0 ${(props) => props.theme.space[2]};
`;

export const PagerButton = styled.div`
  flex-basis: 40%;

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    flex-basis: 30%;
  }
`;

export const PagerButtonNext = styled(PagerButton)`
  ${PagerButtonNextStyles}
`;

export const PagerHeading = styled.h3`
  margin: ${({ theme }) => theme.space[2]};
  font-family: ${(props) => props.theme.fonts.action};
  font-size: 3rem;
  text-transform: uppercase;
  text-align: center;
  padding: 0.8rem 1rem;
  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    margin: 0 33%;
  }
`;
