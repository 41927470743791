import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ThunkDispatch } from "../core/store";
import { getCurrentEvent } from "../core/store/events/reducers";
import { fetchFixturesPhase } from "../core/store/fixtures/thunks";
import { getPhases } from "../core/store/phases/reducers";
import Routes from "./Routes";

const FixturesLoader: React.FC = () => {
  const reduxDispatch = useDispatch<ThunkDispatch>();
  const now = useSelector(getCurrentEvent);
  const activePhaseEvent = now ? now.id : 1;
  const phases = useSelector(getPhases);
  const activePhase = phases.find(
    (p) =>
      p.id > 1 &&
      p.start_event <= activePhaseEvent &&
      p.stop_event >= activePhaseEvent
  );
  const nextPhase = activePhase
    ? phases.find((ph) => ph.id === activePhase.id + 1)
    : null;

  useEffect(() => {
    if (activePhase) {
      reduxDispatch(fetchFixturesPhase(activePhase.id));
      if (nextPhase) {
        reduxDispatch(fetchFixturesPhase(nextPhase.id));
      }
    }
  }, [activePhase, nextPhase, reduxDispatch]);
  return <Routes />;
};
export default FixturesLoader;
