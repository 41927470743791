import { hideVisually, rgba } from "polished";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import styled, { css } from "styled-components/macro";
import {
  getCurrentEvent,
  getEventsById,
} from "../../core/store/events/reducers";
import { getFixturesByEvent } from "../../core/store/fixtures/reducers";
import { getPhases } from "../../core/store/phases/reducers";
import { getTeamsById } from "../../core/store/teams/reducers";
import { ITeam } from "../../core/store/teams/types";
import i18n from "../../i18n";
import { getPrefixedEventShortName } from "../../utils/events";
import { isRTL } from "../../utils/locale";
import Badge from "../Badge";
import Panel from "../Panel";
import Table, { ScrollTable } from "../Table";
import { FixtureCellStyles, FixtureCellPositionStyles } from "../Utils";
import FixtureCell from "./FixtureCell";

const Wrapper = styled.div`
  border-radius: ${({ theme }) => theme.radii[1]};
  box-shadow: 1px 2px 10px 2px
    ${({ theme }) => `${rgba(`${theme.colors.offBlack}`, 0.1)}`};
`;

const PanelPaddingWrap = styled.div`
  padding: ${({ theme }) => theme.space[2]};
`;

const StyledScrollTable = styled(ScrollTable)`
  overflow-x: auto;
`;

const TableFixed = styled(Table)`
  table-layout: fixed;
`;

const TableRow = styled.tr`
  &&& {
    background: transparent;
  }
`;

const StyledTableHeaderNarrow = styled.th`
  &&& {
    position: relative;
    width: 60px;
    border-bottom: none;
    padding: 0.2rem;
  }
`;

const StyledTableHeader = styled.th`
  &&& {
    position: relative;
    width: 80px;
    border-bottom: none;
    overflow: hidden;
    text-align: center;
    padding: 0.2rem;
    font-weight: 500;

    &:first-child {
      z-index: 1;
      position: sticky;
      padding-right: 0.3rem;
      background: ${({ theme }) => theme.colors.white};

      ${isRTL(i18n.language)
        ? css`
            right: 0;
          `
        : css`
            left: 0;
          `};

      @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
        width: 120px;
      }
    }
  }
`;

const TeamNameWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  font-weight: 500;
  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    justify-content: space-between;
  }
`;

const TeamNameDisplay = styled.div`
  padding: 0.2rem;
  @media (max-width: ${({ theme }) => theme.breakpoints[2]}) {
    ${hideVisually()};
  }
`;

const RowTitleCell = styled.td`
  &&& {
    position: sticky;
    left: 0px;
    right: 0px;
    z-index: 1;
    width: 130px;
    padding: 0.2rem;
    border-bottom: none;
    overflow: hidden;

    // Using linear gradient in place of border bottom as border bottom didn't stay sticky

    background-image: linear-gradient(
      ${({ theme }) => theme.colors.white} 98%,
      ${({ theme }) => theme.colors.greyLight} 2%
    );
  }
`;

const StyledTableCell = styled.td`
  &&& {
    padding: 0 0.2rem;
    border-bottom: none;
    position: relative;
  }
`;

const GWDayCell = styled.div`
  ${FixtureCellStyles}
  background-color: #f5f5f5;
  text-align: center;
`;

const Team = styled.div`
  ${FixtureCellPositionStyles}
  ${FixtureCellStyles}
  background-color: #f5f5f5;
`;

const TotalGames = styled.div`
  ${FixtureCellPositionStyles}
  ${FixtureCellStyles}
  background-color: ${({ theme }) =>
    `${rgba(`${theme.colors.offBlack}`, 0.05)}`};
  font-weight: 500;
`;

const TotalFixtures = styled.div`
  ${FixtureCellPositionStyles}
  ${FixtureCellStyles}
  padding: 1.2rem;
  background-color: ${({ theme }) =>
    `${rgba(`${theme.colors.offBlack}`, 0.1)}`};
  font-weight: 500;
`;

const GameweekScheduleTracker = () => {
  const { t } = useTranslation();
  const teamsById = useSelector(getTeamsById);
  const now = useSelector(getCurrentEvent);
  const activePhaseEvent = now ? now.id : 1;
  const fixturesByEvent = useSelector(getFixturesByEvent);
  const activePhase = useSelector(getPhases).find(
    (p) =>
      p.id > 1 &&
      p.start_event <= activePhaseEvent &&
      p.stop_event >= activePhaseEvent
  );
  const eventsById = useSelector(getEventsById);
  const gameDays: [string, number][] = [];
  let totalCounts: Record<string, number> = {};
  const teamsArray: ITeam[] = [];

  Object.values(teamsById).forEach((team) => {
    teamsArray.push(team);
  });

  if (activePhase) {
    for (let i = activePhase.start_event; i <= activePhase.stop_event; i++) {
      gameDays.push([getPrefixedEventShortName(eventsById[i].name), i]);
      teamsArray.forEach((team) => {
        if (!totalCounts[team.id]) {
          totalCounts[team.id] = 0;
        }
        const fixtCount =
          fixturesByEvent[i] &&
          fixturesByEvent[i].find(
            (f) => f.team_h === team.id || f.team_a === team.id
          );
        if (fixtCount) {
          totalCounts[team.id] += 1;
        }
      });
    }
  }

  // Sort the team list alphabetically by name
  teamsArray.sort((a, b) => a.name.localeCompare(b.name, i18n.language));

  return (
    <Wrapper>
      <Panel>
        <Panel.Header
          title={t("gameweekSchedule.title", "Current Gameweek Schedule")}
          upperCase={true}
        />
        <Panel.Body>
          <PanelPaddingWrap>
            <StyledScrollTable>
              <TableFixed>
                <thead>
                  <TableRow>
                    <StyledTableHeader>
                      <Team>Team</Team>
                    </StyledTableHeader>
                    <StyledTableHeaderNarrow>
                      <TotalGames>Games</TotalGames>
                    </StyledTableHeaderNarrow>
                    {gameDays.map(([gameDay, eventId]) => (
                      <StyledTableHeader key={eventId}>
                        <GWDayCell>{gameDay}</GWDayCell>
                      </StyledTableHeader>
                    ))}
                  </TableRow>
                </thead>
                <tbody>
                  {teamsById &&
                    teamsArray.map((team) => {
                      return (
                        <TableRow key={team.id}>
                          <RowTitleCell>
                            <TeamNameWrap>
                              <TeamNameDisplay>{team.name}</TeamNameDisplay>
                              <Badge
                                teamId={team.id}
                                width="40px"
                                isPresentation={true}
                              />
                            </TeamNameWrap>
                          </RowTitleCell>

                          <StyledTableCell key={team.id}>
                            <TotalFixtures>
                              {totalCounts && totalCounts[team.id]}
                            </TotalFixtures>
                          </StyledTableCell>
                          {gameDays.map(([gameDay, eventId]) => (
                            <StyledTableCell key={gameDay}>
                              <FixtureCell
                                teamId={team.id}
                                fixture={
                                  fixturesByEvent[eventId] &&
                                  fixturesByEvent[eventId].find(
                                    (f) =>
                                      f.team_h === team.id ||
                                      f.team_a === team.id
                                  )
                                }
                                teamsById={teamsById}
                              />
                            </StyledTableCell>
                          ))}
                        </TableRow>
                      );
                    })}
                </tbody>
              </TableFixed>
            </StyledScrollTable>
          </PanelPaddingWrap>
        </Panel.Body>
      </Panel>
    </Wrapper>
  );
};

export default GameweekScheduleTracker;
