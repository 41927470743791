import { Link } from "@reach/router";
import * as React from "react";
import styled, { css } from "styled-components/macro";
import { LinkStyles } from "./Link";
import { ControlArrowStart, ControlArrowEnd } from "./icons/Arrows";

interface IBoldLinkStyles {
  color?: "blueDark";
}

interface IBoldLinkWrapProps {
  align?: "left" | "center" | "right";
}

export const BoldLinkWrap = styled.div<IBoldLinkWrapProps>`
  margin: ${({ theme }) => `${theme.space[3]} ${theme.space[2]}`};
  text-align: ${(props) => (props.align ? props.align : "left")};
`;

const BoldLinkStyles = css<IBoldLinkStyles>`
  ${LinkStyles}

  display: inline-flex;
  align-items: center;
  font-size: ${({ theme }) => theme.fontSizes[1]};
`;

const StyledBoldHyperlink = styled.a`
  ${BoldLinkStyles}
`;

const StyledBoldLink = styled(Link)`
  ${BoldLinkStyles}
`;

interface IBoldLinkProps {
  children: React.ReactNode;
  color?: "blueDark";
  to: string;
}

interface IBoldHyperlinkProps {
  children: React.ReactNode;
  href: string;
}

export const BoldHyperlink: React.FC<IBoldHyperlinkProps> = ({
  children,
  href,
}) => (
  <StyledBoldHyperlink href={href}>
    {children}
    <ControlArrowStart color="blueDark" />
  </StyledBoldHyperlink>
);

const BoldLink: React.FC<IBoldLinkProps> = ({ children, color, to }) => (
  <StyledBoldLink to={to}>
    {children}
    <ControlArrowEnd color="blueDark" />
  </StyledBoldLink>
);

export default BoldLink;
