import { size } from "polished";
import styled from "styled-components/macro";
import i18n from "../../i18n";
import { ReactComponent as BaseDownIcon } from "../../img/icons/down.svg";
import { ReactComponent as BaseNewIcon } from "../../img/icons/new.svg";
import { ReactComponent as BaseSameIconLTR } from "../../img/icons/same-ltr.svg";
import { ReactComponent as BaseSameIconRTL } from "../../img/icons/same-rtl.svg";
import { ReactComponent as BaseUpIcon } from "../../img/icons/up.svg";
import { isRTL } from "../../utils/locale";

export const NewIcon = styled(BaseNewIcon)`
  ${size(12)}
  fill: grey;
`;

export const SameIcon = isRTL(i18n.language)
  ? styled(BaseSameIconRTL)`
      ${size(10)}
      fill: grey;
    `
  : styled(BaseSameIconLTR)`
      ${size(10)}
      fill: grey;
    `;

export const UpIcon = styled(BaseUpIcon)`
  ${size(12)}
  fill: ${({ theme }) => theme.colors.success};
`;

export const DownIcon = styled(BaseDownIcon)`
  ${size(12)}
  fill: ${({ theme }) => theme.colors.error};
`;
