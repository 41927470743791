import Tippy from "@tippyjs/react";
import * as React from "react";
import styled, { css } from "styled-components/macro";

const StyledTooltip = styled(Tippy)`
  padding: ${({ theme }) => theme.space[1]} ${({ theme }) => theme.space[2]};
  background-color: white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);

  .tippy-backdrop {
    background-color: white;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  }
  .tippy-content {
    color: black;
    font-size: 1.4rem;
  }

  /* Styling the arrow for different placements */
  &[x-placement^="top"] {
    .tippy-arrow {
      border-top-color: white;
    }
  }

  &[x-placement^="bottom"] {
    .tippy-arrow {
      border-bottom-color: white;
    }
  }
`;

export const TooltipLabel = styled.span<ITooltipLabelProps>`
  text-decoration: underline dotted;
  ${(props) =>
    props.$textDecoration === false &&
    css`
      text-decoration: none;
    `}
`;

export interface ITooltipLabelProps {
  $textDecoration?: boolean;
}

interface IProps {
  content: string;
  children: React.ReactElement<any>;
}

const Tooltip: React.FC<IProps> = ({ children, content }) => {
  // We wrap in a span to stop issues with google translate
  return (
    <StyledTooltip
      content={<span>{content}</span>}
      arrow={true}
      placement="top"
    >
      {children}
    </StyledTooltip>
  );
};

export default Tooltip;
