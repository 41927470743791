import * as styled from "styled-components";

export default styled.createGlobalStyle`
  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }

  html {
    box-sizing: border-box;
    font-family: ${(props) => props.theme.fonts.body};
    font-size: 62.5%;
  }

  body {
    margin: 0;
    font-size: ${(props) => props.theme.fontSizes[1]};
    background-color: white;
    color: black;
    font-feature-settings: "liga", "kern";
  }

  p {
    color: inherit;
    line-height: 1.6;
  }

  svg {
    vertical-align: middle;
  }
`;
