import * as React from "react";
import styled, { css } from "styled-components/macro";
import Tab from "./Tab";
import { ITabPanel } from "./TabPanel";

interface ITabListProps {
  centered?: boolean;
}

const TabList = styled.ul<ITabListProps>`
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  background-color: #ddd;
  list-style-type: none;

  ${({ centered }) =>
    centered === true &&
    css`
      width: 70%;
      margin: 0 auto;

      @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
        width: 35%;
      }
    `}
`;

interface IProps {
  children: Array<React.ReactElement<ITabPanel>>;
  locationHash: string;
  selected: number;
  centered?: boolean;
}

interface IState {
  selected: number;
}

class Tabs extends React.Component<IProps, IState> {
  public static defaultProps = {
    locationHash: "",
    selected: 0,
  };

  public state: IState = {
    selected: this.getSelectedFromLocationHash(),
  };

  public getSelectedFromLocationHash() {
    // Default to prop, can override with location hash
    let selected = this.props.selected;
    if (this.props.locationHash) {
      React.Children.forEach(this.props.children, (child, index) => {
        const panel = child as React.ReactElement<ITabPanel>;
        if (`#${panel.props.link}` === this.props.locationHash) {
          selected = index;
        }
      });
    }
    return selected;
  }

  public handleClick = (index: number) => {
    this.setState({ selected: index });
  };

  public render() {
    const { centered = false } = this.props;
    return (
      <>
        <TabList centered={centered}>
          {React.Children.map(this.props.children, (child, index) => {
            const panel = child as React.ReactElement<ITabPanel>;
            return (
              <Tab
                key={index}
                index={index}
                label={panel.props.label}
                link={panel.props.link}
                onClick={this.handleClick}
                selected={this.state.selected === index}
              />
            );
          })}
        </TabList>
        {this.props.children[this.state.selected]}
      </>
    );
  }
}

export default Tabs;
