import * as Sentry from "@sentry/browser";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import App from "./components/App";
import configureStore from "./core/configureStore";
import "./i18n";

if (process.env.REACT_APP_SENTRY_ENVIRONMENT) {
  Sentry.init({
    dsn: "https://4d3bce4651ce427e969cdc81592b6c72@o118622.ingest.sentry.io/6726347",

    environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
    integrations: [
      new Sentry.BrowserProfilingIntegration(),
      new Sentry.BrowserTracing(),
    ],

    tracesSampleRate: 0.000001,

    tracePropagationTargets: [
      /^https:\/\/nba\.app\/api/,
      /^https:\/\/nba\.staging\.ismgames\.com\/api/,
      /^https:\/\/nbafantasy\.nba\.com\/api/,
    ],

    profilesSampleRate: 0.00001,
  });
}

ReactDOM.render(
  <Provider store={configureStore()}>
    <App />
  </Provider>,
  document.getElementById("root")
);
