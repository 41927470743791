import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { HERO_IMAGES } from "../utils/heroImages";
import { getSite } from "../utils/sites";

const HomeHeroWrap = styled.div`
  margin: ${({ theme }) => theme.space[4]} ${({ theme }) => theme.space[2]};
  position: relative;
`;

const HomeHero = () => {
  const { i18n } = useTranslation();
  const site = getSite();
  const path = `${process.env.PUBLIC_URL}/img/hero/`;

  return (
    <>
      {HERO_IMAGES.map((locale) => {
        const lang =
          locale.site && site === locale.site ? locale.site : locale.language;
        const imagePath = `${path}${lang}`;
        return (locale.site && site === locale.site) ||
          (!locale.site && i18n.language === locale.language) ? (
          <HomeHeroWrap key={locale.language + (locale.site || "")}>
            <picture>
              <source
                media="(min-width: 900px)"
                srcSet={`
                  ${imagePath}/hero-1200.jpg 1200w,
                  ${imagePath}/hero-2400.jpg 2400w
                `}
                sizes="(min-width: 1220px) 1180px, (min-width: 900px) calc(100vw - 40px), 100vw"
              />
              <img
                src={`${imagePath}/hero-400.jpg`}
                srcSet={`
                  ${imagePath}/hero-400.jpg 400w,
                  ${imagePath}/hero-800.jpg 800w
                  `}
                sizes="calc(100vw - 20px)"
                alt={locale.alt_text}
              />
            </picture>
          </HomeHeroWrap>
        ) : null;
      })}
    </>
  );
};

export default HomeHero;
