import * as React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { connect, useSelector } from "react-redux";
import styled from "styled-components/macro";
import { RootState } from "../../core/store";
import { getActiveOrProposedTeamChipName } from "../../core/store/chips/reducers";
import { getElementTypesById } from "../../core/store/element-types/reducers";
import { getElementsById } from "../../core/store/elements/reducers";
import { getNextNEvents } from "../../core/store/events/reducers";
import { IPickProposed } from "../../core/store/my-team/types";
import { getTeamsById } from "../../core/store/teams/reducers";
import { getPrefixedEventShortName } from "../../utils/events";
import { ElementTable } from "../ElementTable";
import { ScrollTable } from "../Table";
import Tooltip, { TooltipLabel } from "../Tooltip";
import MyTeamRow from "./MyTeamRow";
import i18n from "../../i18n";

const MyTeamStatus = styled.th`
  width: 5%;
`;

const MyTeamCpt = styled.th`
  width: 5%;
`;

const MyTeamElement = styled.th`
  width: 34%;
  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    width: 23%;
  }
`;

const MyTeamPos = styled.th`
  width: 12%;
  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    width: 10%;
  }
`;

const MyTeamForm = styled.th`
  width: 11%;
  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    width: 10%;
  }
`;

const MyTeamGD = styled.th`
  width: 11%;
`;

const MyTeamPts = styled.th`
  width: 11%;
`;

const MyTeamFix = styled.th`
  width: 14%;
  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    width: 11%;
  }
`;

interface IOwnProps {
  picks: IPickProposed[];
  renderElementMenu: (index: number) => void;
  title: string;
}

type Props = IOwnProps & WithTranslation;

const MyTeamTable: React.FC<Props> = ({
  t,
  picks,
  renderElementMenu,
  title,
}) => {
  const next3Events = useSelector(getNextNEvents(3));
  return (
    <ScrollTable>
      <ElementTable>
        <thead>
          <tr>
            <MyTeamStatus scope="col">&nbsp;</MyTeamStatus>
            <MyTeamCpt scope="col">&nbsp;</MyTeamCpt>
            <MyTeamElement scope="col">{title}</MyTeamElement>
            <MyTeamPos scope="col">
              <Tooltip content={t("myTeamTable.position", "Position")}>
                <TooltipLabel>
                  {t("myTeamTable.postitionShort", "Pos")}
                </TooltipLabel>
              </Tooltip>
            </MyTeamPos>
            {i18n.language === "he" ? (
              <MyTeamForm scope="col">
                <Tooltip content={t("myTeamTable.form", "Form")}>
                  <TooltipLabel>{t("myTeamTable.formShort", "F")}</TooltipLabel>
                </Tooltip>
              </MyTeamForm>
            ) : (
              <MyTeamForm scope="col">
                {t("myTeamTable.form", "Form")}
              </MyTeamForm>
            )}

            <MyTeamGD scope="col">
              <Tooltip
                content={t(
                  "myTeamTable.roundPointsn",
                  "Gameday fantasy points"
                )}
              >
                <TooltipLabel>
                  {t("myTeamTable.roundPointsShort", "GD")}
                </TooltipLabel>
              </Tooltip>
            </MyTeamGD>
            <MyTeamPts scope="col">
              <Tooltip content={t("myTeamTable.totalPoints", "Total points")}>
                <TooltipLabel>
                  {t("myTeamTable.totalPointsShort", "TP")}
                </TooltipLabel>
              </Tooltip>
            </MyTeamPts>
            {next3Events.length
              ? next3Events.map((e) => (
                  <MyTeamFix scope="colgroup" key={e.id}>
                    {getPrefixedEventShortName(e.name)}
                  </MyTeamFix>
                ))
              : null}
          </tr>
        </thead>
        <tbody>
          {picks.map((p) => (
            <MyTeamRow
              key={p.element}
              pick={p}
              elementId={p.element}
              renderElementMenu={renderElementMenu}
            />
          ))}
        </tbody>
      </ElementTable>
    </ScrollTable>
  );
};

export { MyTeamTable as MyTeamTableTest };

const mapStateToProps = (state: RootState) => {
  return {
    chipInPlayName: getActiveOrProposedTeamChipName(state),
    elementsById: getElementsById(state),
    elementTypesById: getElementTypesById(state),
    teamsById: getTeamsById(state),
  };
};

export default withTranslation()(connect(mapStateToProps)(MyTeamTable));
