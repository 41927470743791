import * as React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components/macro";
import { getTeamsById } from "../core/store/teams/reducers";

const StyledBadge = styled.img<IStyledProps>`
  width: ${(props) => (props.width ? props.width : "100%")};
  display: inline-block;
  vertical-align: middle;
`;

interface IStyledProps {
  width?: string;
}

interface IProps {
  isPresentation?: boolean;
  teamId: number;
}

type Props = IProps & IStyledProps;

const Badge: React.FC<Props> = ({ isPresentation, teamId, width = "" }) => {
  const teamsById = useSelector(getTeamsById);
  const team = teamsById[teamId];

  const imgProps = {
    alt: team.name,
    role: "",
    src: `//cdn.nba.com/logos/nba/${team.code}/global/L/logo.svg`,
    width,
  };

  if (isPresentation) {
    imgProps.alt = "";
    imgProps.role = "presentation";
  }

  return <StyledBadge {...imgProps} />;
};

export default Badge;
