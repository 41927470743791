import { rgba } from "polished";
import * as React from "react";
import styled, { css } from "styled-components/macro";
import { IElement } from "../core/store/elements/types";
import { ITeam } from "../core/store/teams/types";
import { integerToMoney, isMoneyStat } from "../core/utils/money";
import { integerToDecimal, isDecimalStat } from "../utils/decimal";
import ElementDialogButton from "./ElementDialogButton";
import ElementInTable from "./ElementInTable";
import { ElementCell, ElementRow } from "./ElementTable";

const StyledElementListRow = styled(ElementRow)<IStyledProps>`
  ${(props) =>
    props.isProposed &&
    css`
      td,
      button {
        color: ${rgba("black", 0.6)};
      }

      img {
        opacity: 0.6;
      }
    `}
  > td:last-child {
    word-break: break-all;
    white-space: normal;
  }
`;

const ElementListStatCell = styled.td`
  font-family: ${(props) => props.theme.fonts.body};
  font-size: 2rem;
  &&& {
    font-weight: 500;
  }
`;

interface IStyledProps {
  isProposed: boolean;
}

interface IProps extends IStyledProps {
  currencyDivisor: number;
  element: IElement;
  renderElementDialog: () => void;
  renderElementMenu: () => void;
  sort: React.ReactText;
  team: ITeam;
}

const ElementListRow: React.FC<IProps> = ({
  currencyDivisor,
  element,
  isProposed,
  renderElementMenu,
  sort,
  team,
}) => (
  <StyledElementListRow isProposed={isProposed}>
    <td>
      <ElementDialogButton elementId={element.id} variant="list" />
    </td>
    <ElementCell>
      <ElementInTable
        renderElementMenu={renderElementMenu}
        elementId={element.id}
      />
    </ElementCell>
    <ElementListStatCell>
      {integerToMoney(element.now_cost, currencyDivisor)}
    </ElementListStatCell>
    <ElementListStatCell>
      {isMoneyStat(sort)
        ? integerToMoney(element[sort] as number, currencyDivisor)
        : isDecimalStat(sort)
        ? integerToDecimal(element[sort] as number, 10)
        : element[sort]}
    </ElementListStatCell>
  </StyledElementListRow>
);

export default ElementListRow;
