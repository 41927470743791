import i18n from "../i18n";

export const getEventShortName = (eventName: string) =>
  eventName.match(/\d+/g)?.join(".");

export const getPrefixedEventShortName = (eventName: string) =>
  i18n.t("events.prefixedShortName", "GW{{eventName}}", {
    eventName: getEventShortName(eventName),
  });

export const isBlankWeek = (eventId: number) => (eventId === 7 ? true : false);
