export const HERO_IMAGES = [
  {
    language: "de",
    alt_text: "Gewinn eine Reise zu den NBA Finals",
  },
  {
    site: "main_en",
    language: "en",
    alt_text: "Win a trip to the NBA finals",
  },
  {
    language: "el",
    alt_text: "",
  },
  {
    language: "es",
    alt_text: "Gana un viaje a las Finales NBA",
  },
  {
    language: "fr",
    alt_text: "Gagnez un voyage pour les Finales NBA",
  },
  {
    language: "it",
    alt_text: "Vinci un viaggio alle NBA Finals",
  },
  {
    language: "pt",
    alt_text: "Ganhe uma viagem para as finais da NBA",
  },
  {
    language: "ar",
    alt_text: "",
  },
  {
    site: "main_us",
    language: "en",
    alt_text: "Your decisions. Your team.",
  },
  {
    language: "he",
    alt_text: "",
  },
  {
    language: "tr",
    alt_text: "",
  },
];
