import { Link } from "@reach/router";
import * as React from "react";
import styled, { css } from "styled-components/macro";
import { IButtonStyledProps } from "./Button";

const ButtonLinkStyles = css<IButtonStyledProps>`
  display: inline-block;
  padding: 1.5rem ${(props) => props.theme.space[3]};
  border: 0;
  background-color: ${(props) => props.theme.colors.yellow};
  font-family: ${(props) => props.theme.fonts.body};
  font-size: ${(props) => props.theme.fontSizes[1]};
  font-weight: 500;
  border-radius: ${(props) => props.theme.radii[1]};
  color: black;
  text-align: center;
  text-decoration: none;
  line-height: 1;
  cursor: pointer;

  ${(props) =>
    props.fullwidth &&
    css`
      width: 100%;
    `}
`;

export const ButtonHyperlink = styled.a<IButtonStyledProps>`
  ${ButtonLinkStyles}
`;

export const StyledButtonLink = styled(Link)<IButtonStyledProps>`
  ${ButtonLinkStyles}
`;

interface IProps {
  onClick?: () => void;
  to: string;
  children: React.ReactNode;
}

type Props = IProps & IButtonStyledProps;

const ButtonLink: React.FC<Props> = ({
  children,
  fullwidth,
  onClick,
  to,
  variant = "primary",
}) => (
  <StyledButtonLink
    to={to}
    onClick={onClick}
    variant={variant}
    fullwidth={fullwidth}
  >
    {children}
  </StyledButtonLink>
);

export default ButtonLink;
