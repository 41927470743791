import { Link } from "@reach/router";
import React from "react";
import styled from "styled-components";
import { INavLink } from "../../core/hooks/types";
import "./ListItem.css";

const LIStyled = styled.li`
  margin-inline-end: ${({ theme }) => theme.space[1]};
  list-style-type: none;
  display: inline-block;
  vertical-align: middle;
`;

const ListItem = React.forwardRef<HTMLLIElement, INavLink>(
  ({ useRouter, text, href, rel, target }, ref) => {
    return (
      <LIStyled ref={ref}>
        {useRouter ? (
          <Link
            to={href}
            getProps={({ isCurrent, isPartiallyCurrent }) => {
              return {
                className: `ism-nav__tab ${
                  href === "/"
                    ? isCurrent
                      ? "active"
                      : ""
                    : isPartiallyCurrent
                    ? "active"
                    : ""
                }`,
              };
            }}
          >
            {text}
          </Link>
        ) : (
          <a href={href} rel={rel} target={target}>
            <span>{text}</span>
          </a>
        )}
      </LIStyled>
    );
  }
);

export default ListItem;
