import { RouteComponentProps } from "@reach/router";
import range from "lodash/range";
import { hideVisually } from "polished";
import * as React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Box } from "rebass/styled-components";
import styled, { css } from "styled-components/macro";
import { RootState, ThunkDispatch } from "../../core/store";
import {
  getElementsById,
  getElementsEventDataById,
} from "../../core/store/elements/reducers";
import { fetchEventLive } from "../../core/store/elements/thunks";
import {
  IElementsById,
  IElementsEventDataById,
} from "../../core/store/elements/types";
import {
  getEntry,
  getEntryEventFormation,
  getEntryEventPicks,
  getEntryEventPoints,
  getPhaseStandingsForEntryPhaseLeague,
} from "../../core/store/entries/reducers";
import {
  fetchEntryEventPicks,
  fetchEntryPhaseStandings,
  fetchEntrySummary,
} from "../../core/store/entries/thunks";
import {
  IEntry,
  IEntryEvent,
  IEntryPhaseStanding,
  IPickLight,
} from "../../core/store/entries/types";
import {
  getCurrentEvent,
  getEventsById,
} from "../../core/store/events/reducers";
import { IEvent } from "../../core/store/events/types";
import { getFixturesForEventById } from "../../core/store/fixtures/reducers";
import { fetchFixtures } from "../../core/store/fixtures/thunks";
import { IFixture } from "../../core/store/fixtures/types";
import { getSettings } from "../../core/store/game/reducers";
import { ISettings } from "../../core/store/game/types";
import { getPhases } from "../../core/store/phases/reducers";
import { IPhase } from "../../core/store/phases/types";
import { getPlayerData } from "../../core/store/player/reducers";
import { IPlayer } from "../../core/store/player/types";
import { getTeamsById } from "../../core/store/teams/reducers";
import { ITeamsById } from "../../core/store/teams/types";
import { ReactComponent as BaseReload } from "../../img/icons/reload.svg";
import { ChipName, getChipName } from "../../utils/chips";
import { integerToDecimal } from "../../utils/decimal";
import ButtonLink from "../ButtonLink";
import Entry from "../Entry";
import Fixtures from "../Fixtures";
import { Main, Secondary, Wrapper } from "../Layout";
import Link from "../Link";
import {
  EventPager,
  PagerButton,
  PagerButtonNext,
  PagerHeading,
} from "../Pager";
import { NotFound } from "../Routes";
import Table from "../Table";
import Title from "../Title";
import { ControlArrowEnd, ControlArrowStart } from "../icons/Arrows";
import DreamTeam from "../icons/DreamTeam";
import { ScoreboardBox } from "../squad/Scoreboard";
import TabPanel from "../tabs/TabPanel";
import Tabs from "../tabs/Tabs";
import BenchUnit from "./BenchUnit";
import ElementExplainDialog from "./ElementExplainDialog";
import EntryEventTable from "./EntryEventTable";
import PitchFormation from "./PitchFormation";
import TeamElementGroup from "./TeamElementGroup";
import { BenchElements } from "./styles";
import valueForPlayerElement from "./valueForPlayedElement";
import { VisuallyHidden } from "../Utils";
import { isRTL } from "../../utils/locale";
import i18n from "../../i18n";

const PrimaryPanel = styled.div`
  position: relative;
  padding: 1.2rem 0;
`;

const ScoreboardBoxWrapper = styled.div`
  display: flex;
  gap: 1rem;
`;

const PointsScoreboard = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    flex-direction: row;
    align-items: start;
  }
`;

const PrimarySection = styled.div`
  margin-bottom: ${(props) => props.theme.space[2]};
  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    flex: 0 0 45%;
    padding-inline-end: ${(props) => props.theme.space[2]};
  }
`;

const SecondarySection = styled.div`
  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    flex: 0 0 55%;
  }
`;

const PrimaryHeading = styled.h4`
  margin: 0;
  font-family: ${(props) => props.theme.fonts.body};
  font-size: 1.6rem;
  font-weight: 500;
`;

const PrimaryValue = styled.div`
  font-family: ${(props) => props.theme.fonts.body};
  font-size: 4.3rem;
  font-weight: 500;
  padding: 0.2rem 0;
`;

const ReloadButton = styled.button`
  position: absolute;
  bottom: 0.5rem;
  ${isRTL(i18n.language)
    ? css`
        right: 0;
      `
    : css`
        left: 0;
      `}
  padding: ${({ theme }) => theme.space[1]};
  border: 0;
  background-color: ${({ theme }) => theme.colors.primary};
  line-height: 1;
  cursor: pointer;
`;

const Reload = styled(BaseReload)`
  fill: white;
  ${isRTL(i18n.language)
    ? css`
        transform: scaleX(-1);
      `
    : css`
        transform: scaleX(1);
      `}
`;

const ReloadButtonText = styled.span`
  ${hideVisually()}
`;

const SecondaryList = styled.ul`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  list-style-type: none;
`;

const StyledSecondaryItem = styled.li`
  flex: 0 0 calc(50% - 0.5rem);
  margin-bottom: ${(props) => props.theme.space[2]};
  &:last-child {
    flex: 1;
  }
`;

const SecondaryItemInner = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  font-family: ${(props) => props.theme.fonts.body};
  font-size: 1.6rem;
`;

const SecondaryHeading = styled.h4`
  flex: 1 0 auto;
  margin: 0;
  font-weight: 500;
  text-align: center;
  font-size: 1.4rem;

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    text-align: start;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    font-size: 1.6rem;
  }
`;

const SecondaryValue = styled.div`
  flex: 1;
  text-align: center;
  font-weight: 500;
  font-size: 2.6rem;

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    text-align: end;
  }
`;

// const DreamTeamLinkIcon = styled(BaseDreamTeam)`
//   ${size(16)}
//   margin-right: ${({ theme }) => theme.space[1]};
//   color: white;
//   fill: #005baa;
// `;

// const DreamTeamLink = styled(Link)`
//   display: inline-flex;
//   align-items: center;
//   justify-content: space-around;
//   border-radius: 15px;
//   padding: 0.6rem;
//   background-color: white;
//   text-decoration: none;
// `;

const ChipStatus = styled.div`
  position: absolute;
  ${isRTL(i18n.language)
    ? css`
        right: 13%;
      `
    : css`
        left: 13%;
      `}
  bottom: -1rem;
  width: 88%;
  padding: 0.6rem;
  border-radius: ${({ theme }) => `${theme.radii[1]} ${theme.radii[1]} 0 0`};
  background-image: linear-gradient(
    to right,
    ${({ theme }) => theme.colors.primary},
    ${({ theme }) => theme.colors.blue}
  );
  color: white;
  font-size: 1.2rem;
  font-family: ${({ theme }) => theme.fonts.body};
  text-align: center;

  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    ${isRTL(i18n.language)
      ? css`
          right: 10%;
        `
      : css`
          left: 10%;
        `}
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    ${isRTL(i18n.language)
      ? css`
          right: 13%;
        `
      : css`
          left: 13%;
        `}
  }
`;

interface ISecondaryItem {
  heading: string;
  value: React.ReactNode;
}

const SecondaryItem: React.FC<ISecondaryItem> = ({ heading, value }) => (
  <StyledSecondaryItem>
    <ScoreboardBox>
      <SecondaryItemInner>
        <SecondaryHeading>{heading}</SecondaryHeading>
        <SecondaryValue>{value}</SecondaryValue>
      </SecondaryItemInner>
    </ScoreboardBox>
  </StyledSecondaryItem>
);

type OwnProps = RouteComponentProps<{ entryId: string; eventId: string }> &
  WithTranslation;

interface IPropsFromState {
  elementsById: IElementsById;
  elementsDataById: IElementsEventDataById;
  entry: IEntry | null;
  entryData: IEntryEvent | null;
  event: IEvent | null;
  fixturesById: Record<string, IFixture> | null;
  formation: string;
  mine: boolean;
  now: IEvent | null;
  eventPoints: number;
  settings: ISettings;
  teamsById: ITeamsById;
  player: IPlayer;
  entryPhaseStandings: IEntryPhaseStanding | undefined;
  activePhase: IPhase | undefined;
}

interface IPropsFromDispatch {
  fetchEntryEventPicks: (entryId: number, eventId: number) => void;
  fetchEntrySummary: (entryId: number) => void;
  fetchEventLive: (entryId: number) => void;
  fetchFixturesForEvent: (eventId: number) => void;
  fetchEntryPhaseStandings: (entryId: number, phaseId: number) => void;
}

type Props = OwnProps & IPropsFromState & IPropsFromDispatch;

interface IState {
  pickForMenu: IPickLight | null;
}

class EntryEvent extends React.Component<Props, IState> {
  public state: IState = {
    pickForMenu: null,
  };

  public handleShowMenuForPickElement = (element: number) => {
    const matches = this.props.entryData!.picks.filter(
      (p) => p.element === element
    );
    if (matches.length) {
      this.setState({ pickForMenu: matches[0] });
    }
  };

  public handleHideMenuForPick = () => {
    this.setState({ pickForMenu: null });
  };

  public renderDreamTeam = (pick: IPickLight) =>
    this.props.elementsDataById &&
    this.props.elementsDataById[pick.element] &&
    this.props.elementsDataById[pick.element].stats.in_dreamteam ? (
      <Link to={`/dream-team/${this.props.event!.id}`}>
        <DreamTeam />
      </Link>
    ) : null;

  public fetchDataAndFixtures = () => {
    this.fetchData();
    this.props.fetchFixturesForEvent(Number(this.props.eventId));
  };

  public fetchData = () => {
    this.props.fetchEntrySummary(Number(this.props.entryId));
    this.props.fetchEntryEventPicks(
      Number(this.props.entryId),
      Number(this.props.eventId)
    );
    this.props.fetchEventLive(Number(this.props.eventId));
    if (this.props.eventId && this.props.entryId) {
      this.props.fetchEntryPhaseStandings(
        Number(this.props.entryId),
        this.props.activePhase!.id
      );
    }
  };

  public componentDidMount() {
    this.fetchData();
  }

  public componentDidUpdate(prevProps: Props) {
    if (
      prevProps.eventId !== this.props.eventId ||
      prevProps.entryId !== this.props.entryId
    ) {
      this.fetchData();
    }
  }
  public render() {
    const {
      elementsById,
      elementsDataById,
      entry,
      entryData,
      event,
      fixturesById,
      formation,
      mine,
      now,
      eventPoints,
      settings,
      t,
      teamsById,
      entryPhaseStandings,
      activePhase,
    } = this.props;

    // Handle unknown and unstarted events
    if (!now || !event || event.id > now.id) {
      return <NotFound />;
    }
    if (!entry || !entryData) {
      return null;
    }
    const phasePoints = entryPhaseStandings
      ? Number(entryPhaseStandings.total)
      : 0;
    const chipName = entryData.active_chip
      ? getChipName(entryData.active_chip as ChipName, t)
      : "";

    // Create a new function on each render as data could have changed and
    // need to ensure a render of connected subcomponents
    const renderPickValue = valueForPlayerElement({
      elementsById,
      elementsDataById,
      fixturesById,
      teamsById,
    });
    const benchHeading: Record<string, React.ReactNode> = {
      11: (
        <abbr title={t("entryEvent.goalkeeper.title", "Goalkeeper")}>
          {t("entryEvent.goalkeeper.abbr", "GK")}
        </abbr>
      ),
      12: "1",
      13: "2",
      14: "3",
    };
    // Handle events before the entry started
    return (
      <Wrapper>
        <Main>
          <VisuallyHidden as="div">
            <h2>
              {t("entryEvent.title", "Points")} - {entry.name}
            </h2>
          </VisuallyHidden>
          {/* ROUND PAGER */}
          <Box mb={4}>
            <ScoreboardBox>
              <Box mx={2}>
                <PagerHeading>{event.name}</PagerHeading>
              </Box>
              {(event.id > entry.started_event || event.id < now.id) && (
                <EventPager>
                  {event.id > entry.started_event && (
                    <PagerButton>
                      <ButtonLink
                        to={`/entry/${entry.id}/event/${event.id - 1}`}
                        variant="secondary"
                        fullwidth="true"
                      >
                        <ControlArrowStart />
                        {t("entryEvent.prev", "Previous")}
                      </ButtonLink>
                    </PagerButton>
                  )}
                  {event.id < now.id && (
                    <PagerButtonNext>
                      <ButtonLink
                        to={`/entry/${entry.id}/event/${event.id + 1}`}
                        variant="secondary"
                        fullwidth="true"
                      >
                        {t("entryEvent.next", "Next")}
                        <ControlArrowEnd />
                      </ButtonLink>
                    </PagerButtonNext>
                  )}
                  {/* Also need to check isn't last event */}
                  {mine && event.id === now.id && (
                    <PagerButtonNext>
                      <ButtonLink
                        to={`/my-team`}
                        variant="secondary"
                        fullwidth="true"
                      >
                        {t("entryEvent.next", "Next")}
                        <ControlArrowEnd />
                      </ButtonLink>
                    </PagerButtonNext>
                  )}
                </EventPager>
              )}
            </ScoreboardBox>
          </Box>
          {/* SCOREBOARD */}
          <Box mb={2}>
            <PointsScoreboard>
              <PrimarySection>
                <ScoreboardBoxWrapper>
                  <ScoreboardBox>
                    <PrimaryHeading>
                      {activePhase
                        ? t(
                            "entryEvent.activePhase.latestPoints",
                            "{{ ActivePhaseName }} Points",
                            {
                              ActivePhaseName: activePhase.name,
                            }
                          )
                        : t("entryPhase.latestPoints", "Gameweek not started")}
                    </PrimaryHeading>
                    <PrimaryValue>
                      {integerToDecimal(phasePoints, 10)}
                    </PrimaryValue>
                  </ScoreboardBox>
                  <ScoreboardBox>
                    <PrimaryHeading>
                      {event.finished
                        ? t("entryEvent.finalPoints", "Final Gameday Points")
                        : t("entryEvent.latestPoints", "Latest Gameday Points")}
                    </PrimaryHeading>
                    <PrimaryValue>
                      {integerToDecimal(eventPoints, 10)}
                      {!event.finished && (
                        <ReloadButton onClick={this.fetchDataAndFixtures}>
                          <ReloadButtonText>
                            {t("entryEvent.reload", "Reload Points")}
                          </ReloadButtonText>
                          <Reload />
                        </ReloadButton>
                      )}
                    </PrimaryValue>
                    <PrimaryPanel>
                      {chipName && (
                        <ChipStatus>
                          {t(
                            "entryEvent.chip.played",
                            "{{ chipName }} played",
                            {
                              chipName: chipName,
                            }
                          )}
                        </ChipStatus>
                      )}
                    </PrimaryPanel>
                  </ScoreboardBox>
                </ScoreboardBoxWrapper>
                {/* <Box mb={2}>
                  <DreamTeamLink to={`/dream-team/${event.id}`}>
                    <DreamTeamLinkIcon />
                    {t("entryEvent.dreamTeam", "Team of the Week")}
                    <ControlArrowRight />
                  </DreamTeamLink>
                </Box> */}
              </PrimarySection>
              <SecondarySection>
                <SecondaryList>
                  <SecondaryItem
                    heading={t("entryEvent.average", "Average GD Pts")}
                    value={
                      integerToDecimal(event.average_entry_score, 10) || "-"
                    }
                  />
                  <SecondaryItem
                    heading={t("entryEvent.highest", "Highest Pts")}
                    value={
                      event.highest_scoring_entry ? (
                        <Link
                          to={`/entry/${event.highest_scoring_entry}/event/${event.id}`}
                        >
                          {integerToDecimal(
                            event.highest_score as number,
                            10
                          ) || "???"}
                        </Link>
                      ) : (
                        "-"
                      )
                    }
                  />
                  <SecondaryItem
                    heading={t("entryEvent.rank", "GD Rank")}
                    value={
                      entryData.entry_history.rank
                        ? entryData.entry_history.rank.toLocaleString()
                        : "-"
                    }
                  />
                </SecondaryList>
              </SecondarySection>
            </PointsScoreboard>
          </Box>

          {/* TEAM */}
          <Tabs centered>
            <TabPanel
              label={t("entryEvent.pitchView", "Court View")}
              link="pitch"
            >
              <Box mb="4px">
                <TeamElementGroup
                  title={t("myTeam.starters", "Starters")}
                  isHiddenTitle={true}
                >
                  <PitchFormation
                    chipName={entryData.active_chip}
                    formation={formation}
                    picks={entryData.picks}
                    renderDreamTeam={this.renderDreamTeam}
                    renderElementMenu={this.handleShowMenuForPickElement}
                    renderPickValue={renderPickValue}
                  />
                </TeamElementGroup>
              </Box>
              <Box mb={4}>
                <TeamElementGroup
                  title={t("myTeam.substitutesn", "Bench")}
                  isBench={true}
                >
                  <BenchElements>
                    {range(
                      settings.squad_squadplay,
                      settings.squad_squadsize
                    ).map((i) => (
                      <BenchUnit
                        key={i}
                        chipName={entryData.active_chip}
                        heading={benchHeading[i]}
                        pick={entryData.picks[i]}
                        renderDreamTeam={this.renderDreamTeam}
                        renderElementMenu={() =>
                          this.handleShowMenuForPickElement(
                            entryData.picks[i].element
                          )
                        }
                        renderPickValue={renderPickValue}
                      />
                    ))}
                  </BenchElements>
                </TeamElementGroup>
              </Box>
            </TabPanel>
            <TabPanel label={t("entryEvent.listView", "List View")} link="list">
              <Box my={4} bg="white">
                <EntryEventTable
                  title={t("entryEvent.starters", "Starters")}
                  picks={entryData.picks.slice(0, settings.squad_squadplay)}
                  renderElementMenu={this.handleShowMenuForPickElement}
                  chipName={entryData.active_chip}
                  dataById={elementsDataById}
                />
                <EntryEventTable
                  title={t("entryEvent.substitutesn", "Bench")}
                  picks={entryData.picks.slice(settings.squad_squadplay)}
                  renderElementMenu={this.handleShowMenuForPickElement}
                  chipName={entryData.active_chip}
                  dataById={elementsDataById}
                />
              </Box>
            </TabPanel>
          </Tabs>

          {entryData.automatic_subs.length > 0 && (
            <>
              <Box mx={2}>
                <Title>
                  {t("entryEvent.autoSubs.title", "Automatic Substitutions")}
                </Title>
              </Box>
              <Table>
                <thead>
                  <tr>
                    <th>{t("entryEvent.autoSubs.playerOut", "Player out")}</th>
                    <th>{t("entryEvent.autoSubs.playerIn", "Player in")}</th>
                  </tr>
                </thead>
                <tbody>
                  {entryData.automatic_subs.map((s) => (
                    <tr key={s.element_out}>
                      <td>{elementsById[s.element_out].web_name}</td>
                      <td>{elementsById[s.element_in].web_name}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </>
          )}
          {this.state.pickForMenu && (
            <ElementExplainDialog
              elementId={this.state.pickForMenu.element}
              eventId={event.id}
              closeDialog={this.handleHideMenuForPick}
            />
          )}
          <Fixtures eventId={event.id} />
        </Main>
        <Secondary>
          <Entry entryId={entry.id} />
        </Secondary>
      </Wrapper>
    );
  }
}
export { EntryEvent as EntryEventTest };

const mapStateToProps = (
  state: RootState,
  ownProps: OwnProps
): IPropsFromState => {
  const entryId = Number(ownProps.entryId);
  const eventId = Number(ownProps.eventId);
  const player = getPlayerData(state) as IPlayer;
  const entry = getEntry(state, entryId);
  const phases = getPhases(state);
  const overallLeague = entry
    ? entry.leagues.classic.filter((l) => l.short_name === "overall")
    : null;
  const activePhase = phases.find(
    (p) =>
      p.id > 1 &&
      p.start_event <= Number(eventId) &&
      p.stop_event >= Number(eventId)
  );
  const entryPhaseStandings =
    activePhase && overallLeague
      ? getPhaseStandingsForEntryPhaseLeague(
          state,
          entryId,
          activePhase.id,
          overallLeague[0].id
        )
      : undefined;
  return {
    elementsById: getElementsById(state),
    elementsDataById: getElementsEventDataById(state, eventId),
    entry,
    event: getEventsById(state)[eventId],
    now: getCurrentEvent(state),
    entryData: getEntryEventPicks(state, entryId, eventId),
    fixturesById: getFixturesForEventById(state, eventId),
    formation: getEntryEventFormation(state, entryId, eventId),
    eventPoints: getEntryEventPoints(state, entryId, eventId),
    settings: getSettings(state) as ISettings,
    teamsById: getTeamsById(state),
    mine: Boolean(player && player.entry && player.entry === entryId),
    player,
    entryPhaseStandings,
    activePhase,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch): IPropsFromDispatch => ({
  fetchEntryEventPicks: (entryId, eventId) =>
    dispatch(fetchEntryEventPicks(entryId, eventId)),
  fetchEntrySummary: (entryId) => dispatch(fetchEntrySummary(entryId)),
  fetchEventLive: (eventId) => dispatch(fetchEventLive(eventId)),
  fetchFixturesForEvent: (eventId) => dispatch(fetchFixtures(eventId)),
  fetchEntryPhaseStandings: (entryId: number, phaseId: number) =>
    dispatch(fetchEntryPhaseStandings(entryId, phaseId)),
});

// Have to split declarations due to TS error - https://github.com/DefinitelyTyped/DefinitelyTyped/issues/50211
const connected = connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(EntryEvent));
const translated = withTranslation()(connected);

export default translated;
