import { ellipsis, rgba } from "polished";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import styled, { css } from "styled-components/macro";
import { RootState, ThunkDispatch } from "../../core/store";
import { getElementsById } from "../../core/store/elements/reducers";
import { IElementsById } from "../../core/store/elements/types";
import {
  getEntry,
  getPhaseStandingsForEntryPhaseLeague,
} from "../../core/store/entries/reducers";
import { fetchEntryPhaseStandings } from "../../core/store/entries/thunks";
import { IEntry, IEntryPhaseStanding } from "../../core/store/entries/types";
import { getCurrentEvent } from "../../core/store/events/reducers";
import { IEvent } from "../../core/store/events/types";
import { getPhases } from "../../core/store/phases/reducers";
import { IPhase } from "../../core/store/phases/types";
import { getPlayerData } from "../../core/store/player/reducers";
import { IPlayer } from "../../core/store/player/types";
import i18n from "../../i18n";
import { integerToDecimal } from "../../utils/decimal";
import { isRTL } from "../../utils/locale";
import ButtonLink from "../ButtonLink";

const alignment = isRTL(i18n.language) ? "to left" : " to right";

const EventInfoGradient = css`
  background-image: linear-gradient(
    ${alignment},
    ${(props) => props.theme.colors.red} 10%,
    ${(props) => props.theme.colors.pink}
  );
`;

const StyledEventInfo = styled.div`
  margin: ${({ theme }) => theme.space[2]};
  padding: 0.3rem 0;

  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    margin: ${({ theme }) => theme.space[4]};
    padding: 0.3rem;
    border-radius: ${({ theme }) => theme.radii[2]};
    box-shadow: 1px 2px 10px 2px
      ${({ theme }) => `${rgba(`${theme.colors.offBlack}`, 0.1)}`};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    margin-inline-start: 0;
    margin-inline-end: 0;
  }
`;

const Background = styled.div`
  background-color: ${(props) => props.theme.colors.white};

  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    padding: ${(props) => props.theme.space[3]};
    border-radius: ${(props) => props.theme.radii[1]};
  }
`;

const EventItems = styled.ul`
  display: flex;
  gap: ${(props) => props.theme.space[4]};
  flex-direction: column;
  margin: 0;
  padding: 0;

  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    justify-content: space-around;
    flex-direction: row;
  }
`;

const EventItem = styled.li`
  ${EventInfoGradient}
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: ${({ theme }) => `${theme.space[2]} ${theme.space[4]}`};
  border-radius: ${({ theme }) => theme.radii[2]};
  color: ${({ theme }) => theme.colors.white};
  list-style-type: none;
  font-size: ${({ theme }) => theme.fontSizes[3]};

  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    align-self: center;
    padding: ${({ theme }) => `${theme.space[7]} ${theme.space[3]}`};
  }
`;

const ButtonWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.space[2]};
  flex: 0.7;
  align-self: center;
  width: 100%;
`;

const ItemHeading = styled.h3`
  flex: 0 0 calc(65%);
  margin: 0;
  color: inherit;
  font-size: ${({ theme }) => theme.fontSizes[1]};
  font-weight: 400;
  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    flex: 0 0 calc(60%);
    font-size: ${({ theme }) => theme.fontSizes[2]};
  }
`;

const ItemValue = styled.div`
  flex: 0 0 calc(35%);
  ${ellipsis()};
  font-size: 3rem;
  color: inherit;
  text-align: end;
  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    flex: 0 0 calc(40%);
    font-size: ${({ theme }) => theme.fontSizes[4]};
  }
`;

interface IPropsFromState {
  elementsById: IElementsById;
  entry: IEntry | null;
  now: IEvent | null;
  player: IPlayer;
  entryPhaseStandings: IEntryPhaseStanding | undefined;
  activePhase: IPhase | undefined;
}

interface IPropsFromDispatch {
  fetchEntryPhaseStandings: (entryId: number, phaseId: number) => void;
}

type Props = IPropsFromState & IPropsFromDispatch;

const EventInfo: React.FC<Props> = ({
  entry,
  now,
  fetchEntryPhaseStandings,
  activePhase,
  entryPhaseStandings,
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (activePhase && entry) {
      fetchEntryPhaseStandings(entry.id, activePhase.id);
    }
  }, [activePhase, entry, fetchEntryPhaseStandings]);

  if (!now || !entry) {
    return null;
  }

  const heGameWeek = activePhase?.name.split(" ")[2];
  const gameWeek =
    i18n.language === "he" ? heGameWeek : activePhase?.name.split(" ")[1];
  const gameDay = now.name;

  const phaseTotalPoints = entryPhaseStandings
    ? Number(entryPhaseStandings.total)
    : 0;

  return (
    <StyledEventInfo>
      <Background>
        <EventItems>
          <EventItem>
            <ItemHeading>
              {t("eventInfo.GameweekPoints", "Gameweek {{gameWeek}} points", {
                gameWeek,
              })}
            </ItemHeading>
            <ItemValue>
              {integerToDecimal(phaseTotalPoints, 10) || "-"}
            </ItemValue>
          </EventItem>
          <EventItem>
            <ItemHeading>
              {t("eventInfo.GamedayPoints", "{{ gameDay }} points", {
                gameDay,
              })}
            </ItemHeading>
            <ItemValue>
              {entry.summary_event_points
                ? integerToDecimal(entry.summary_event_points, 10)
                : "-"}
            </ItemValue>
          </EventItem>
          <ButtonWrap>
            <ButtonLink to="/my-team" fullwidth="true">
              {t("myTeamN", "Edit line-up")}
            </ButtonLink>
            <ButtonLink to="/transfers" fullwidth="true">
              {t("transactionsN", "Make Transactions")}
            </ButtonLink>
            <ButtonLink to="/leagues" fullwidth="true">
              {t("myLeaguesN", "View leagues")}
            </ButtonLink>
          </ButtonWrap>
        </EventItems>
      </Background>
    </StyledEventInfo>
  );
};

const mapStateToProps = (state: RootState): IPropsFromState => {
  const player = getPlayerData(state) as IPlayer;
  const entryId = player.entry || 0;
  const now = getCurrentEvent(state) as IEvent;
  const phases = getPhases(state);
  const entry = getEntry(state, entryId);
  const overallLeague = entry
    ? entry.leagues.classic.filter((l) => l.short_name === "overall")
    : null;
  const activePhase = phases.find(
    (p) =>
      p.id > 1 &&
      p.start_event <= Number(now.id) &&
      p.stop_event >= Number(now.id)
  );
  const entryPhaseStandings =
    activePhase && overallLeague
      ? getPhaseStandingsForEntryPhaseLeague(
          state,
          entryId,
          activePhase.id,
          overallLeague[0].id
        )
      : undefined;
  return {
    elementsById: getElementsById(state),
    entry: getEntry(state, entryId),
    now,
    player,
    entryPhaseStandings,
    activePhase,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch): IPropsFromDispatch => ({
  fetchEntryPhaseStandings: (entryId: number, phaseId: number) =>
    dispatch(fetchEntryPhaseStandings(entryId, phaseId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EventInfo);
