import * as React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components/macro";
import { RootState } from "../core/store";
import { getElement } from "../core/store/elements/reducers";

export const StyledShirt = styled.img<IStyledProps>`
  width: ${(props) => (props.width ? props.width : "100%")};
  vertical-align: middle;
`;

interface IStyledProps {
  width?: string;
}

interface IProps {
  elementId: number;
}

type Props = IProps & IStyledProps;

const Shirt: React.FC<Props> = ({ elementId, width }) => {
  const element = useSelector((state: RootState) =>
    getElement(state, elementId)
  );

  if (!element) {
    return null;
  }

  return (
    <StyledShirt
      src={`//cdn.nba.com/headshots/nba/latest/520x380/${element.code}.png`}
      alt=""
      role="presentation"
      onError={({ currentTarget }) => {
        currentTarget.onerror = null; // prevents looping
        currentTarget.src =
          "//cdn.nba.com/headshots/nba/latest/520x380/logoman.png";
      }}
      width={width}
    />
  );
};

export default Shirt;
