import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import styled, { css } from "styled-components/macro";
import Badge from "../components/Badge";
import { IElement } from "../core/store/elements/types";
import { getNextEvent } from "../core/store/events/reducers";
import { getFixturesByEvent } from "../core/store/fixtures/reducers";

interface IStyledPitchElementData {
  elementTypeId: number;
}

interface IProps {
  element: IElement;
}

export const ElementFixturesWrapper = styled.div`
  padding: ${({ theme }) => theme.space[1]};
`;

export const NextFixtureWrapper = styled.div`
  display: flex;
  justify-content: center;
  height: ${({ theme }) => theme.space[6]};
  margin-bottom: ${({ theme }) => theme.space[1]};
  padding: ${({ theme }) => theme.space[1]};
  border-top-left-radius: ${({ theme }) => theme.radii[1]};
  border-top-right-radius: ${({ theme }) => theme.radii[1]};
  background-color: ${({ theme }) => theme.colors.white};
  opacity: 0.98;
`;

export const FixtureField = styled.div<IStyledPitchElementData>`
  flex: 1;
  align-self: center;
  padding: 0px 8px;
  text-align: start;
  &:first-child {
    margin: auto;
    ${(props) =>
      props.elementTypeId &&
      css`
        border-inline-end: 1.5px solid
          ${props.theme.colors.elementTypes[props.elementTypeId].colors[1]}50;
      `}
    text-align: end;
  }
`;

export const UpcomingFixturesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.space[1]};
  padding: ${({ theme }) => theme.space[1]};
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  background-color: ${({ theme }) => theme.colors.white};
  opacity: 0.96;
`;

export const UpcomingTitle = styled.div<IStyledPitchElementData>`
  padding: ${({ theme }) => theme.space[1]};
  ${(props) =>
    props.elementTypeId &&
    css`
      border-bottom: 1.5px solid
        ${props.theme.colors.elementTypes[props.elementTypeId].colors[1]}50;
    `};
`;

export const UpcomingFixtures = styled.ul`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin: 0;
  padding: 0;
`;

const UpcomingField = styled.li<IStyledPitchElementData>`
  flex: 1;
  padding: ${({ theme }) => theme.space[0]};
  list-style: none;
  ${(props) =>
    props.elementTypeId &&
    css`
      border-inline-end: 1.5px solid
        ${props.theme.colors.elementTypes[props.elementTypeId].colors[1]}50;
    `};
  &:last-child {
    border: none;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    padding: ${({ theme }) => theme.space[1]};
  }
`;

const renderBadge = (teamId: number) => {
  return <Badge teamId={teamId} width="23px" />;
};

const PitchElementFixtures: React.FC<IProps> = ({ element }) => {
  const { t } = useTranslation();
  // We don't use getFixturesForEventByTeam as hooks shouldn't be used in loops
  const fixturesByEvent = useSelector(getFixturesByEvent);
  const nextEvent = useSelector(getNextEvent);
  const neededFixtures = 5;
  const elementFixtures: number[] = new Array(neededFixtures);
  elementFixtures.fill(0);
  const teamId = element.team;

  if (nextEvent) {
    for (let i = 0; i < neededFixtures; i++) {
      const eventId = nextEvent.id + i;
      if (fixturesByEvent[eventId]) {
        // No chance of a team having more than one fixture
        const fixture = fixturesByEvent[eventId].find(
          (f) => f.team_h === teamId || f.team_a === teamId
        );
        if (fixture) {
          elementFixtures[i] =
            teamId === fixture.team_h ? fixture.team_a : fixture.team_h;
        }
      }
    }
  }
  return (
    <ElementFixturesWrapper>
      <NextFixtureWrapper>
        <FixtureField elementTypeId={element.element_type}>
          {t("fixtures.next", "Next")}
        </FixtureField>
        <FixtureField elementTypeId={element.element_type}>
          {elementFixtures[0] ? renderBadge(elementFixtures[0]) : "-"}
        </FixtureField>
      </NextFixtureWrapper>
      <UpcomingFixturesWrapper>
        <UpcomingTitle elementTypeId={element.element_type}>
          {t("fixtures.upcoming", "Upcoming")}
        </UpcomingTitle>
        <UpcomingFixtures>
          {elementFixtures.splice(1).map((opponentId, index) =>
            opponentId ? (
              <UpcomingField
                key={`${index}${opponentId}`}
                elementTypeId={element.element_type}
              >
                {renderBadge(opponentId)}
              </UpcomingField>
            ) : (
              <UpcomingField
                key={`${index}${opponentId}`}
                elementTypeId={element.element_type}
              >
                -
              </UpcomingField>
            )
          )}
        </UpcomingFixtures>
      </UpcomingFixturesWrapper>
    </ElementFixturesWrapper>
  );
};

export default PitchElementFixtures;
