import { size } from "polished";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { connect, useSelector } from "react-redux";
import styled from "styled-components/macro";
import { RootState } from "../../core/store";
import { getElementTypesBySquadPosition } from "../../core/store/element-types/reducers";
import { IElementTypesBySquadPosition } from "../../core/store/element-types/types";
import { getElementsById } from "../../core/store/elements/reducers";
import { IElement, IElementsById } from "../../core/store/elements/types";
import { getNextEvent, getNextNEvents } from "../../core/store/events/reducers";
import { getFixturesByEvent } from "../../core/store/fixtures/reducers";
import {
  getProposedElements,
  getSavedPicks,
} from "../../core/store/squad/reducers";
import { IProposedElements, ISavedPicks } from "../../core/store/squad/types";
import { integerToMoney } from "../../core/utils/money";
import { ReactComponent as NoFixtureHyphen } from "../../img/icons/no-fixture-hyphen.svg";
import { integerToDecimal } from "../../utils/decimal";
import { getPrefixedEventShortName } from "../../utils/events";
import { getNextElementFixtures } from "../../utils/fixtures";
import Badge from "../Badge";
import ElementDialogButton from "../ElementDialogButton";
import ElementInTable from "../ElementInTable";
import { ElementCell, ElementRow, ElementTable } from "../ElementTable";
import { ScrollTable } from "../Table";
import Tooltip, { TooltipLabel } from "../Tooltip";

const SquadListStatus = styled.th`
  width: 7%;
`;

const StyledElementType = styled.span`
  font-weight: 500;
  text-transform: uppercase;
`;

const SquadListElement = styled.th`
  width: 30%;
  font-family: ${(props) => props.theme.fonts.body};

  ${StyledElementType} {
    font-size: ${(props) => props.theme.fontSizes[3]};
  }
`;

const SquadListPrice = styled.th`
  width: 11%;
`;

const SquadListForm = styled.th`
  width: 10%;
`;

const SquadListPoints = styled.th`
  width: 10%;
`;

export const SquadListFixture = styled.th`
  width: 12%;
`;

const NoFixture = styled(NoFixtureHyphen)`
  ${size(16)}
  margin: auto;
`;

const FixtureCell = styled.td`
  text-align: center;
`;

interface ISquadListRowProps {
  elementsById: IElementsById;
  elementTypesByPosition: IElementTypesBySquadPosition | null;
  proposedElements: IProposedElements;
  pos: number;
  renderElementMenu: (position: number) => void;
  savedPicks: ISavedPicks;
}

const TransfersListRow: React.FC<ISquadListRowProps> = ({
  elementsById,
  elementTypesByPosition,
  proposedElements,
  pos,
  renderElementMenu,
  savedPicks,
}) => {
  let element = proposedElements[pos];
  // TODO - We need a visual indicator of the row elementState, in the past we
  // have rendered the '0' shirt instead of the regular one
  let elementState: "original" | "removed" | "replaced" = "original";
  if (!element) {
    element = elementsById[savedPicks[pos].element];
    elementState = "removed";
  } else if (element.id !== savedPicks[pos].element) {
    elementState = "replaced";
  }

  const elementRowVariant = elementState === "removed" ? "invalid" : "";
  const fixturesByEvent = useSelector(getFixturesByEvent);
  const nextEvent = useSelector(getNextEvent);
  const neededFixtures = 5;
  const elementFixtures =
    element &&
    getNextElementFixtures(nextEvent, neededFixtures, fixturesByEvent, element);

  if (!elementTypesByPosition) {
    return null;
  }

  return (
    <ElementRow variant={elementRowVariant}>
      <td>
        <ElementDialogButton elementId={element.id} variant="list" />
      </td>
      <ElementCell>
        <ElementInTable
          renderElementMenu={() => renderElementMenu(pos)}
          elementId={element.id}
        />
      </ElementCell>

      {elementState === "replaced" ? (
        <td>{integerToMoney(element.now_cost, 10)}</td>
      ) : (
        <td>{integerToMoney(savedPicks[pos].selling_price, 10)}</td>
      )}
      <td>{integerToDecimal(Number(element.form), 10)}</td>
      <td>{integerToDecimal(element.total_points, 10)}</td>

      {elementFixtures.map((fixture, index) =>
        fixture.code ? (
          <FixtureCell key={`${fixture.code}-${index}`}>
            <Badge
              teamId={
                fixture.team_a === element.team
                  ? fixture.team_h
                  : fixture.team_a
              }
              width="30px"
            />
          </FixtureCell>
        ) : (
          <FixtureCell key={`${fixture.event}-${index}`}>
            <NoFixture />
          </FixtureCell>
        )
      )}
    </ElementRow>
  );
};

interface IPropsFromState {
  elementsById: IElementsById;
  elementTypesByPosition: IElementTypesBySquadPosition | null;
  proposedElements: IProposedElements;
  savedPicks: ISavedPicks;
}

interface IOwnProps {
  elementType: number;
  positions: number[];
  renderElementDialog: (element: IElement) => void;
  renderElementMenu: (pos: number) => void;
}

type Props = IPropsFromState & IOwnProps & WithTranslation;

const TransfersListTable: React.SFC<Props> = ({
  t,
  elementType,
  elementTypesByPosition,
  positions,
  ...rest
}) => {
  const next5Events = useSelector(getNextNEvents(5));

  if (!elementTypesByPosition) {
    return null;
  }

  const elementPosition =
    elementTypesByPosition[positions[0]].thisType.plural_name;

  return (
    <ScrollTable $wide={true}>
      <ElementTable elementTypeId={elementType}>
        <thead>
          <tr>
            <SquadListStatus>&nbsp;</SquadListStatus>
            <SquadListElement>
              <StyledElementType>
                {elementPosition === "Front Court"
                  ? t("elementType.frontcourtLong", "{{ elementPosition }}", {
                      elementPosition: elementPosition,
                    })
                  : t("elementType.backcourtLong", "{{ elementPosition}}", {
                      elementPosition: elementPosition,
                    })}
              </StyledElementType>
            </SquadListElement>
            <SquadListPrice>
              <Tooltip
                content={t("transferListTable.sellingPricen", "Selling Salary")}
              >
                <TooltipLabel>
                  {t("squadListTabel.label.sellingPricen", "SS")}
                </TooltipLabel>
              </Tooltip>
            </SquadListPrice>
            <SquadListForm>
              <Tooltip content={t("transferListTable.form", "Form")}>
                <TooltipLabel>
                  {t("squadListTabel.label.form", "F")}
                </TooltipLabel>
              </Tooltip>
            </SquadListForm>
            <SquadListPoints>
              <Tooltip
                content={t("transferListTable.totalPoints", "Total Points")}
              >
                <TooltipLabel>
                  {t("squadListTabel.label.totalPoints", "TP")}
                </TooltipLabel>
              </Tooltip>
            </SquadListPoints>

            {next5Events.length
              ? next5Events.map((e) => (
                  <SquadListFixture scope="colgroup" key={e.id}>
                    {getPrefixedEventShortName(e.name)}
                  </SquadListFixture>
                ))
              : null}
          </tr>
        </thead>
        <tbody>
          {positions.map((p) => (
            <TransfersListRow
              key={p}
              pos={p}
              elementTypesByPosition={elementTypesByPosition}
              {...rest}
            />
          ))}
        </tbody>
      </ElementTable>
    </ScrollTable>
  );
};

const mapStateToProps = (state: RootState): IPropsFromState => ({
  elementsById: getElementsById(state),
  elementTypesByPosition: getElementTypesBySquadPosition(state),
  proposedElements: getProposedElements(state),
  savedPicks: getSavedPicks(state),
});

export default withTranslation()(connect(mapStateToProps)(TransfersListTable));
