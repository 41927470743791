import { ReactComponent as BaseGovLogo } from "../img/gov-logo.svg";
import { ReactComponent as BaseFdj18Logo } from "../img/fdj-18.svg";
import { size } from "polished";
import styled from "styled-components";

const Wrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 3%;
  width: 100%;
  padding: ${(props) => props.theme.space[3]};
  background-color: ${(props) => props.theme.colors.partnerYellow};
`;

const GovLogo = styled(BaseGovLogo)`
  flex-shrink: 0;
  ${size(80, 160)}

  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    ${size(100, 180)}
  }
`;

const Fdj18Logo = styled(BaseFdj18Logo)`
  ${size(46)}
  margin-left: ${(props) => props.theme.space[3]};
`;

const CopyWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;
`;

const FdjCopyLarge = styled.p`
  margin: 0;
  text-transform: uppercase;
  font-size: ${(props) => props.theme.fontSizes[1]};
  font-weight: 500;
  line-height: 1.4;

  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    font-size: ${(props) => props.theme.fontSizes[2]};
    line-height: 1.6;
  }
`;

const FdjCopySmall = styled(FdjCopyLarge)`
  font-size: ${(props) => props.theme.fontSizes[0]};

  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    font-size: ${(props) => props.theme.fontSizes[1]};
  }
`;

const FdjSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: ${(props) => props.theme.space[2]};
  margin-top: ${(props) => props.theme.space[2]};
`;

const FdjDisclaimer = () => {
  return (
    <FdjSection>
      <Fdj18Logo />
      <Wrap>
        <CopyWrap>
          <FdjCopyLarge>
            les jeux d'argent et de hasard peuvent être dangereux: pertes
            d'argent, conflits familiaux, addiction ...{" "}
          </FdjCopyLarge>
          <FdjCopySmall>
            retrouvez nos conseils sur joueurs-info-service.fr (09 74 75 13 13 -
            appel non surtaxé)
          </FdjCopySmall>
        </CopyWrap>
        <GovLogo />
      </Wrap>
    </FdjSection>
  );
};

export default FdjDisclaimer;
